export class DelayedFunction {
	_function: () => void;
	delay: number;
	constructor(_function: () => void, delay: number) {
		this._function = _function;
		this.delay = delay;
	}
	execute(): void {
		if (this.delay) {
			setTimeout(this._function, this.delay);
		} else {
			this._function();
		}
	}
}