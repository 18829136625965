
Date.prototype.toCustomDateString = function(): string {
	const y = this.getFullYear();
	let m = '' + (this.getMonth() + 1);
	let d = '' + this.getDate();
	if (m.length < 2) m = '0' + m;
	if (d.length < 2) d = '0' + d;
	return [y, m, d].join('-');
}

Date.prototype.toGermanDateString = function(): string {
	const y = this.getFullYear();
	let m = '' + (this.getMonth() + 1);
	let d = '' + this.getDate();
	if (m.length < 2) m = '0' + m;
	if (d.length < 2) d = '0' + d;
	return [d, m, y].join('.');
}

Date.prototype.toHumanDateString = function(): string {
	const locale = window.navigator.language;
	return this.toLocaleString(locale, { weekday: 'long' }) + ", "
		+ this.getDate() + ". "
		+ this.toLocaleString(locale, { month: "long" }) + " "
		+ this.getFullYear();
}

Date.prototype.getTimeString = function(): string {
	let hours = this.getHours().toString().padStart(2, "0");
	let minutes = this.getMinutes().toString().padStart(2, "0");
	return hours + ":" + minutes;
}

Date.prototype.toUnixTime = function(): number {
	return Math.floor(this.getTime() / 1000);
}
