import {
	trigger,
	animate,
	style,
	transition,
	group,
	query,
	stagger,
	sequence,
	animateChild
} from '@angular/animations'

export function getCardAnimationDecoration() {
	return {
		animations: [
			cardTransition
		],
		host: {
			"[@cardTransition]": "",
			"class": "perspective-space"
		},
	}
}

export function getRouterTransition(widthOffset: string) {
	return trigger('routerTransition' + widthOffset, [
		
		transition('henslauftraegeid <=> henslwaagscheineid', [
			query(':enter, :leave', style({
				position: 'fixed',
				width: 'calc(100% - 4em - ' + widthOffset + ')'
			}), { optional: true }),
			query(':enter', style({ visibility: 'hidden' }), { optional: true }),
			sequence([
				query(':leave', animateChild(), { optional: true }), 
				group([
					query(':leave', style({ visibility: 'hidden' }), { optional: true }),
					query(':enter', style({ visibility: 'visible' }), { optional: true }),
				]),
				query(':enter', animateChild(), { optional: true }), 
			])
		]),
		
		transition('* => *, void => *', [
			query(':enter, :leave', style({
				position: 'fixed',
				width: 'calc(100% - 4em - ' + widthOffset + ')'
			}), { optional: true }),
			query(':enter', style({
				opacity: 0,
				transform: 'translateX(-2%)'
			}), { optional: true }),
			sequence([
				query(':leave', animateChild(), { optional: true }), 
				group([
					query(':leave', [
						style({
							opacity: 1,
							transform: 'translateX(0%)'
						}),
						animate('80ms ease-in-out', style({
							opacity: 0,
							transform: 'translateX(10%)'
						}))
					], { optional: true }),
					query(':enter', [
						style({
							opacity: 0,
							transform: 'translateX(-2%)'
						}),
						animate('100ms ease-in-out', style({
							opacity: 1,
							transform: 'translateX(0%)'
						}))
					], { optional: true }),
				]),
				query(':enter', animateChild(), { optional: true }), 
			])
		])
		
	]);
}

export const cardTransition = trigger('cardTransition', [
	transition(':enter', [
		query('mat-card, .table-wrapper', style({
			opacity: 0,
			transform: 'rotateX(-5deg) translate3d(0, 2em, 1em)'
		})),
		query('.mat-raised-button', style({
			opacity: 0,
			transform: 'translateX(-2em)'
		}), { optional: true }),
		query('mat-card, .table-wrapper', stagger(90, [
			animate('100ms ease-out', style({
				opacity: 1,
				transform: 'rotateX(0) translate3d(0, 0, 0)'
			})),
		])),
		query('.mat-raised-button', stagger(-80, [
			animate('90ms ease-out', style({
				opacity: 1,
				transform: 'translateY(0)'
			}))
		]), { optional: true }),
	]),
	transition(':leave', [
		query('.mat-raised-button', stagger(-100, [
			style({
				opacity: 1,
				transform: 'translateY(0)'
			}),
			animate('60ms ease-in', style({
				opacity: 0,
				transform: 'translateX(2em)'
			})),
		]), { optional: true }),
		query('mat-card, .table-wrapper', stagger(90, [
			style({
				opacity: 1,
				transform: 'rotateX(0) translate3d(0, 0, 0)'
			}),
			animate('110ms ease-in', style({
				opacity: 0,
				transform: 'rotateX(-4deg) translate3d(-2em, -10em, -5em)'
			})),
		]))
	])
]);
