import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogBuilder } from 'lib/classes/dialog-builder';
import { interval, call } from 'lib/utils/functions';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { Utils } from 'lib/utils/utils';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NotificationService
{
	public errors: any[] = [];
	public onError: () => void;
	private dialogBuilder: DialogBuilder = null;
	
	constructor(
		protected http: HttpClient,
		protected dialog: MatDialog
	) {
		interval("showDialogOnError", () => {
			this.showDialogOnError();
		}, 1000);
	}
	
	showDialogOnError() {
		if (!this.dialogBuilder && this.errors.length > 0) {
			call(this.onError);
			let messages: string = this.errors.map(e => e[0] as string).join("\n");
			this.dialogBuilder = new DialogBuilder(this.dialog, "Fehler", 
				"Ein Fehler ist aufgetreten. An Entwickler übermitteln?<br>"
				+ "<pre style='"
					+ "border: 1px solid #c60; "
					+ "padding: 0.5em 0.8em; "
					+ "max-width: 400px; "
					+ "white-space: inherit; "
					+ "box-shadow: 0 0 25px -10px black; "
					+ "border-radius: 2px; "
					+ "font-size: 1.1em; "
					+ "line-height: 1em; "
					+ "color: #930; "
					+ "background: #fed; "
				+ "'>" + messages + "</pre>"
			);
			this.dialogBuilder.inputLabel = "Nachricht hinterlassen";
			let ask = this.dialogBuilder.ask();
			ask.dialog.subscribe(yes => {
				if (yes) {
					let message = "";
					if (ask.instance.inputValue) {
						message = "<h3>Nachricht</h3><p>" + ask.instance.inputValue + "</p>";
					}
					let stacks: string = this.errors.map(e => e[1] as string).join("\n\n");
					let body = "<pre>" + stacks + "</pre>" + message;
					let setToError = () => {
						this.dialogBuilder.set(
							"Fehler",
							"Fehler beim Senden der Mail.<br>"
								+ "Der Entwickler konnte nicht benachrichtigt werden."
						);
					};
					let resetDialog = () => {
						this.dialogBuilder.info().dialog.subscribe(() => {
							this.errors = [];
							this.dialogBuilder = null;
						});
					};
					this.mail(
						"error@chvaco.at",
						"s.mueck@chvaco.at",
						"Fehler von Angular",
						"<html><body>" + body + "</body></html>"
					).subscribe(sent => {
						if (sent) {
							this.dialogBuilder.set(
								"Erfolgreich",
								"Der Entwickler wurde benachrichtigt."
							);
						} else {
							setToError();
						}
						resetDialog();
					}, error => {
						setToError();
						resetDialog();
					});
				} else {
					this.errors = [];
					this.dialogBuilder = null;
				}
			});
		}
	}
	
	jsonp(url: string, params: any): ExtendedObservable<boolean> {
		return ExtendedObservable.from(
			this.http.jsonp<boolean>(Utils.createURL(url, params), 'callback')
		);
	}
	
	mail(from: string, to: string, title: string, text: string): ExtendedObservable<boolean> {
		return this.jsonp("https://gantt.chvaco.at/api/index.php/v3/wog/mail/", {
			from: from, to: to, title: title, text: text
		});
	}
}
