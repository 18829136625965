export const ONE_UNIX_SECOND = 1;
export const ONE_UNIX_MINUTE = 60;
export const ONE_UNIX_HOUR = 3600;
export const ONE_UNIX_DAY = 86400;

export class ServerTime
{
	timestamp: number = 0;
	unixtime: number = 0;
	unixdate: number = 0;
}
