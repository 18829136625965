import 'hammerjs';
import { NgModule, ErrorHandler } from '@angular/core';
import { DateAdapter } from '@angular/material';
import { DateFormat } from 'lib/date-format';
import { getAllDeclarations } from 'lib/imports/declarations';
import { getAllModules } from 'lib/imports/modules';
import { AppComponent } from './app.component';
import { DialogComponent } from 'lib/dialog.component';
import { CustomErrorHandler } from 'lib/custom-error-handler';

@NgModule({
	declarations: getAllDeclarations(),
	imports: getAllModules(),
	entryComponents: [
		DialogComponent
	],
	providers: [
		{
			provide: DateAdapter,
			useClass: DateFormat
		},
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandler
		}
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule
{
	constructor(private dateAdapter: DateAdapter<any>) {
		dateAdapter.setLocale('de-de');
	}
}
