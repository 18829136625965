import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Fraechter } from 'app/hensl/classes/fraechter';
import { Fahrzeug } from 'app/hensl/classes/fahrzeug';
import { Fahrzeugtyp } from 'app/hensl/classes/fahrzeugtyp';
import { Land } from 'app/hensl/classes/land';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { merge } from 'lib/utils/functions';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-fraechter',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Frächter</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'fraechternummer', label: 'Frächter-Nr.' },
					{ name: 'fraechtername', label: 'Name' },
					{ name: 'plz', label: 'PLZ' },
					{ name: 'ort', label: 'Ort' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neuer Frächter</button>
		</div>
		<form *ngIf="id">
			<mat-card style="float: left; margin-right: 2em; ">
				<div>
					<textfield _focus
						label="Frächternummer"
						value="current.fraechternummer"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Frächtername"
						value="current.fraechtername"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Adresse"
						value="current.adresse"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Postleitzahl"
						value="current.plz"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Ort"
						value="current.ort"
						[wave]="true"
					></textfield>
				</div>
				
				<div>
					<autocomplete
						label="Land" value="current.landid"
						[from]="laender" [wave]="true"
					></autocomplete>
				</div>
				
				<div>
					<textfield
						label="Telefon"
						value="current.telefon"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="E-Mail"
						value="current.email"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="UID"
						value="current.uid"
						[wave]="true"
					></textfield>
				</div>
				
				<div>
					<checkbox
						label="Hat Frächterpreise"
						value="current.hatfraechterpreis"
					></checkbox>
				</div>
				
			</mat-card>
			
			<mat-card style="float: left; " *ngIf="current.id">
				<div *ngFor="let fahrzeug of vorhandeneFahrzeuge">
					<mat-form-field style="margin-right: 0.5em; ">
						<mat-select placeholder="Fahrzeugtyp" [(value)]="fahrzeug.fahrzeugtypid">
							<mat-option *ngFor="let fahrzeugtyp of fahrzeugtypen" [value]="fahrzeugtyp.id">
								{{ fahrzeugtyp.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field><input
						matInput
						type="text"
						placeholder="Kennzeichen"
						[value]="fahrzeug.kennzeichen"
						(change)="fahrzeug.kennzeichen = $event.target.value"
					></mat-form-field>
					<button
						type="button"
						mat-mini-fab
						color="warn"
						style="transform: scale(0.83); "
						(click)="deleteFahrzeug(fahrzeug)"
					><mat-icon>delete_forever</mat-icon></button>
				</div>
				<div>
					<button
						type="button"
						mat-mini-fab
						color="primary"
						(click)="addFahrzeug()"
					><mat-icon>add</mat-icon></button>
				</div>
			</mat-card>
			<div style="clear: both; "></div>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class FraechterComponent extends FormComponent<Fraechter>
{
	fahrzeuge: Fahrzeug[] = [];
	fahrzeugtypen: Fahrzeugtyp[];
	laender: Land[];
	
	get vorhandeneFahrzeuge() {
		return this.fahrzeuge.filter(x => !x._deleted);
	}
	
	getTable(): string { return "henslfraechterliste"; }
	
	getNew(): Fraechter {
		return new Fraechter();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		this.loadFahrzeuge();
		this.data.loader += 2;
		this.data.selectAll(() => new Fahrzeugtyp(), "henslfahrzeugtypen", "order by name").subscribe(typen => {
			this.fahrzeugtypen = typen;
			this.data.loader--;
		});
		this.data.selectAll(() => new Land(), "hensllaender").subscribe(laender => {
			this.laender = laender;
			this.data.loader--;
		});
	}
	
	afterSave(callback: () => void): void {
		this.saveFahrzeuge(() => {
			super.afterSave(callback);
		});
	}
	
	saveFahrzeuge(callback: () => void): void {
		let queries: ExtendedObservable<any>[] = [];
		for (const fahrzeug of this.fahrzeuge) {
			if (fahrzeug._deleted) {
				if (fahrzeug.id > 0) {
					queries.add(this.data.delete("/api/dynamic/henslfahrzeuge", { id: fahrzeug.id }));
				}
			} else {
				queries.add(this.data.set("/api/dynamic/henslfahrzeuge", fahrzeug));
			}
		}
		if (queries.length == 0) {
			queries.add(ExtendedObservable.empty);
		}
		forkJoin(...queries).subscribe(() => {
			callback();
		});
	}
	
	loadFahrzeuge() {
		this.data.loader++;
		this.data.query(() => new Fahrzeug(),
			"select * from henslfahrzeuge where fraechterid = " + this.current.id
		).subscribe(fahrzeuge => {
			this.fahrzeuge = fahrzeuge;
			this.data.loader--;
		});
	}
	
	addFahrzeug() {
		let fahrzeug = new Fahrzeug();
		fahrzeug.fraechterid = this.current.id;
		this.fahrzeuge.add(fahrzeug);
	}
	
	deleteFahrzeug(fahrzeug: Fahrzeug) {
		fahrzeug._deleted = true;
	}
}
