import {
	RootComponent
} from 'root.component';

import {
	AppComponent
} from 'app.component';

import {
	TextFieldComponent,
	EuroFieldComponent,
	NumberFieldComponent,
	CheckBoxComponent,
	AutoCompleteComponent,
	AutoCompleteTextComponent,
	DateFieldComponent,
	TimeFieldComponent,
	DataTableComponent,
	FormButtonsComponent,
	LoaderComponent
}  from 'lib/components';

import {
	DialogComponent
} from 'lib/dialog.component';

export function getAllComponents() {
	return [
		RootComponent,
		AppComponent,
		TextFieldComponent,
		EuroFieldComponent,
		NumberFieldComponent,
		CheckBoxComponent,
		AutoCompleteComponent,
		AutoCompleteTextComponent,
		DateFieldComponent,
		TimeFieldComponent,
		DataTableComponent,
		FormButtonsComponent,
		LoaderComponent,
		DialogComponent
	];
}
