import { Component } from "@angular/core";
import { InputComponent } from "lib/components/input.component";
import { isString } from "util";

@Component({
	selector: 'datefield',
	styles: [`
		mat-form-field {
			margin: 0 1em 0 0;
		}
	`],
	template: `
		<mat-form-field
			[style.width]="INPUT_width ? INPUT_width : '100px'"
		>
			<input
				matInput
				#input
				[matDatepicker]="picker"
				[formControl]="control"
				[placeholder]="INPUT_label"
				[_disabled]="!INPUT_enabled"
				[value]="getValue()"
				(focusin)="selectText($event)"
				(change)="setValue($event.target.value)"
				(dateChange)="onDate($event)"
				(keydown.enter)="tabToNext($event)"
				(keydown.ArrowLeft)="tabToPrevious($event)"
				(keydown.ArrowRight)="tabToNext($event)"
			>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
	`
})
export class DateFieldComponent extends InputComponent
{
	getValue() {
		let value = super.getValue();
		// if (value instanceof String) {
		// 	return value.getDate();
		// }
		return value;
	}
	
	setValue(value: any): void {
		if (value instanceof Date) {
			// value = value.toCustomDateString();
		} else if (isString(value)) {
			// value = value.toCustomDateString();
			value = value.getDate();
		}
		super.setValue(value);
	}
	
	onDate(e) {
		this.setValue(e.target.value);
		this.OUTPUT_change.emit(null);
	}
}
