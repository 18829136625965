import { Injectable } from '@angular/core';
import { DatabaseService } from 'lib/services/database.service';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'lib/services/notification.service';

@Injectable({ providedIn: 'root' })
export class DataService extends DatabaseService
{
	loader: number = 0;
	
	get loading(): boolean {
		return this.loader > 0;
	}
	
	getAPIPath() {
		return "/api";
	}
	
	constructor(protected http: HttpClient, protected service: NotificationService) {
		super(http);
		service.onError = () => { this.loader = 0; };
	}
	
	public set(url: string, data: any): ExtendedObservable<any> {
		data = (d => {
			let result = {};
			for (let key in d) {
				if (!key.startsWith("_")) {
					result[key] = this.getValueForSQL(d[key]);
				}
			}
			return result;
		})(data);
		
		if (data.id) {
			return ExtendedObservable.from(
				this.http.put<any>(url + '/' + data.id, data, this.defaultOptions)
			);
		}
		return ExtendedObservable.from(
			this.http.post<any>(url, data, this.defaultOptions)
		);
	}
	
	private getValueForSQL(value: any): any {
		if (typeof value === 'boolean') {
			return value ? 1 : 0;
		} else if (value instanceof Date) {
			return value.toCustomDateString();
		}
		return value;
	}
	
	public delete(url: string, data: any): ExtendedObservable<any> {
		if (data.id) {
			return ExtendedObservable.from(
				this.http.delete<any>(url + '/' + data.id)
			);
		} else {
			return ExtendedObservable.of(null);
		}
	}
}
