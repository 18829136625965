import { Component } from "@angular/core";
import { InputComponent } from "lib/components/input.component";

@Component({
	selector: 'numberfield',
	styles: [`
		mat-form-field {
			margin: 0 1em 0 0;
		}
	`],
	template: `
		<mat-form-field
			[style.width]="(INPUT_width ? INPUT_width : '100px')"
		>
			<input
				matInput
				#input
				type="number"
				[formControl]="control"
				[placeholder]="INPUT_label"
				[_disabled]="!INPUT_enabled"
				[value]="getValue()"
				(focusin)="selectText($event)"
				(input)="setValue($event.target.value)"
				(keydown.enter)="tabToNext($event)"
				(keydown.ArrowLeft)="tabToPrevious($event)"
				(keydown.ArrowRight)="tabToNext($event)"(keydown.left)="tabToPrevious($event)"
			>
		</mat-form-field>
	`
})
export class NumberFieldComponent extends InputComponent
{
	onLoad() {
		super.onLoad();
		this.control.setValue(this.getValue());
	}
}
