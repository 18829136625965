import { Injectable } from '@angular/core';
import { DataService } from 'lib/services/data.service';
import { Settings } from 'app/hensl/classes/settings';

@Injectable()
export class SettingsService
{
	settings: Settings;
	
	constructor(data: DataService) {
		this.settings = new Settings(data);
		this.settings.add("debug.showLoadingIntensity", false);
		this.settings.add("debug.showAnimationState", false);
		this.settings.add("form.defaultNachStation", 0);
		this.settings.add("form.defaultTransportart", 0);
		this.settings.add("view.maxHeutigeAuftraege", 5);
		this.settings.add("view.menuStaysOpen", false);
		this.settings.add("debug.notizenEntwicklungOffen", "");
		this.settings.add("debug.notizenEntwicklungAbgeschlossen", "");
	}
	
	load(...loadFunctions: (() => void)[]) {
		this.settings.load(() => {
			for (const loadFunction of loadFunctions) {
				loadFunction();
			}
		});
	}
	
	getValue(name: string) {
		return this.settings.get(name).value;
	}
	
	setValue(name: string, value: any) {
		this.settings.set(name, value).save();
	}
	
	isValueLoaded(name: string) {
		return this.settings.get(name).loaded;
	}
}
