import { IUnique } from "lib/interfaces/i.unique";
import { Utils } from "lib/utils/utils";
import { DataService } from "lib/services/data.service";
import { ExtendedObservable } from "lib/classes/extended-observable";

export class Rechnung implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	anlagedatum: Date = new Date();
	aenderungsdatum: Date = new Date();
	rechnungsdatum: Date = new Date();
	rechnungsnummer: string = "";
	fraechternummer: string = "";
	referenznummer: string = "";
	rechnungsempfaengerid: number = 0;
	rechnungsadresse: string = "";
	artikelid: number = 0;
	artikeltext: string = "";
	verladestelle: string = "";
	warenempfaenger: string = "";
	menge: number = 0.000;
	preis: number = 0.000;
	zusaetzlicherbetrag: number = 0.000;
	
	constructor(private _data: DataService) {
		
	}
	
	static selectAll(data: DataService, max: number): ExtendedObservable<any[]> {
		const top = max > 0 ? "top " + max : "";
		const order = max > 0 ? "order by id desc" : "";
		return data.query<any>(null, "select " + top + " r.* from henslrechnungen as r " + order).convert(values => {
			
			return values;
		});
	}
	
	static select(data: DataService, id: number): ExtendedObservable<Rechnung> {
		return data.single(() => new Rechnung(data), `
			select top 1 a.*
			from henslrechnungen as a
			where a.id = ` + id
		);
	}
	
	static neueRechnungsnummer(data: DataService): ExtendedObservable<any> {
		return data.single<any>(null, `
			select id, (nummer + 1) as nummer
			from henslnummernkreise
			where gruppe = 'Rechnung'
			and jahr = ` + Utils.getYear()
		);
	}
	
	saveNummernkreis(nummernkreis: any): ExtendedObservable<void> {
		let neueNummer = nummernkreis.nummer;
		return this._data.set("/api/dynamic/henslnummernkreise", {
			id: nummernkreis.id,
			nummer: neueNummer
		}).convert(result => {
			this.rechnungsnummer = (neueNummer - 30000) + " / " + (Utils.getYear() - 2000);
			return result;
		});
	}
}