import { getAllComponents } from 'lib/imports/components';
import { getAllDirectives } from 'lib/imports/directives';
import { getAllPipes } from 'lib/imports/pipes';

import {
	getAllComponents as getAllComponentsFromHensl
} from 'app/hensl/imports';

export function getAllDeclarations() {
	return combineImports(
		getAllComponents(),
		getAllComponentsFromHensl(),
		getAllDirectives(),
		getAllPipes()
	);
}

function combineImports(...arrays) {
	let result = [];
	for (let array of arrays) {
		for (let item of array) {
			result.push(item);
		}
	}
	return result;
}
