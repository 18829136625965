import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Attribut } from 'app/hensl/classes/attribut';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { merge } from 'lib/utils/functions';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-attribute',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Attribute</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'name', label: 'Name' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neues Attribut</button>
		</div>
		<form *ngIf="id">
			<mat-card>
				<div>
					<textfield _focus
						label="Name"
						value="current.name"
						[wave]="true"
					></textfield>
					<checkbox
						label="Ist eindeutig"
						value="current.isteindeutig"
					></checkbox>
				</div>
				<div>
					<checkbox
						label="Für Aufträge"
						value="current.fuerauftraege"
					></checkbox>
				</div>
				<div>
					<checkbox
						label="Für Waagscheine"
						value="current.fuerwaagscheine"
					></checkbox>
				</div>
				<div>
					<checkbox
						label="Für Rechnungen"
						value="current.fuerrechnungen"
					></checkbox>
				</div>
				<div>
					<checkbox
						label="Ist Produzent"
						value="current.istproduzent"
					></checkbox>
				</div>
			</mat-card>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class AttributeComponent extends FormComponent<Attribut>
{
	getTable(): string { return "henslattribute"; }
	
	getNew(): Attribut {
		return new Attribut();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		
	}
}
