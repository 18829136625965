import { Component, OnInit } from "@angular/core";
import { SettingsService } from "app/hensl/services/settings.service";
import { BatschImport } from "app/hensl/classes/batsch-import";
import { DataService } from "lib/services/data.service";
import { forkJoin } from "rxjs";
import { Waagschein } from "app/hensl/classes/waagschein";
import { Station } from "app/hensl/classes/station";
import { Fahrzeug } from "app/hensl/classes/fahrzeug";
import { Artikel } from "app/hensl/classes/artikel";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { Utils } from "lib/utils/utils";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Tarif } from "app/hensl/classes/tarif";
import { DialogBuilder } from "lib/classes/dialog-builder";

@Component({
	selector: "hensl-importe",
	styles: [`
		div.entry {
			margin: 0 0 1em 1em;
		}
	`],
	template: `
		<h2>Importe</h2>
		
		<h3>Importierte Daten {{ getImportedCount() }}</h3>
		<datatable [searchbar]="false" [clickable]="false"
			[data-source]="importedDataSource"
			[columns]="[
				{ name: 'vonstation', label: 'Von-Station' },
				{ name: 'anstation', label: 'An-Station' },
				{ name: 'lieferdatum', label: 'Lieferdatum' },
				{ name: 'kennzeichen', label: 'Kennzeichen' },
				{ name: 'produzent', label: 'Produzent' },
				{ name: 'netto', label: 'Netto' },
				{ name: '_temp', label: 'Waagschein', type: 'link' }
			]"
		></datatable>
		
		<br>
		<a href="https://{{ getInternalHostname() }}/import-tool" target="_blank">Import-Tool öffnen</a>
		
		<!--h3>Hochgeladene Dateien</h3>
		<div *ngFor="let file of files">
			<a (click)="importFile(file)">{{ file }}</a>
		</div>
		<br>
		<div>
			<a href="/html/hensl/scripts/upload.php" target="_blank"><b>Weitere Datei hochladen...</b></a>
		</div>
		
		<div style='margin: 0.5em 0; '>
			<button mat-raised-button
				(click)="importLoaded()" color="primary"
				[disabled]="!loadedPreview"
			>Importieren</button>
		</div>
		
		<div [innerHTML]='loadedPreview | html'></div-->
	`
})
export class ImporteComponent implements OnInit
{
	importedData: BatschImport[];
	importedDataSource;
	
	batschImporte: BatschImport[];
	
	files: string[];
	loaded: BatschImport[];
	loadedPreview: string = "";
	
	constructor(
		private settings: SettingsService,
		private data: DataService,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpClient,
		private dialog: MatDialog
	) {
		settings.load();
		// this.data.get("/html/hensl/uploads/?json=1").subscribe(files => {
		// 	this.files = files;
		// });
		// 
		this.refreshDataSource();
	}
	
	getInternalHostname() {
		return Utils.getInternalHostname();
	}
	
	getImportedCount() {
		if (this.importedData) {
			if (this.importedData.length > 100) {
				// return "(" + this.importedData.length + ")";
				return "(100+)";
			} else {
				return "(" + this.importedData.length + ")";
			}
		}
		return "";
	}
	
	refreshDataSource() {
		const __this = this;
		__this.data.query(() => new BatschImport(), "select TOP 101 * from henslbatschimporte where waagscheinid = 0 order by id asc").subscribe(imports => {
			__this.importedData = [];
			for (const _import of imports) {
				_import._temp = function() {
					if (_import.waagscheinid > 0) {
						return [
							"Zum Waagschein",
							function() {
								Utils.goTo(__this.router, __this.route, "../waagscheine/" + _import.waagscheinid);
							}
						];
					}
					return [
						"Erstellen",
						function() {
							__this.waagscheinErstellen(_import);
							_import._temp = function() {
								return [
									"Bitte warten...",
									function() { }
								];
							};
						}
					];
				}
				__this.importedData.add(_import);
			}
			__this.importedDataSource = new MatTableDataSource(__this.importedData);
		});
	}
	
	ngOnInit() {
		forkJoin(
			this.data.selectAll(() => new BatschImport(), "henslbatschimporte")
		).subscribe(result => {
			this.batschImporte = result[0];
		});
	}
	
	importFile(file) {
		this.data.read("/html/hensl/uploads/" + file).subscribe(lines => {
			this.loaded = [];
			for (let i = 1; i < lines.length; i++) {
				let batschImport = new BatschImport();
				batschImport.dateiname = file;
				batschImport.index = i - 1;
				batschImport.fromCSV(lines[i].split(';'));
				this.loaded.add(batschImport);
			}
			this.loadedPreview = "<table border='1'>";
			this.loadedPreview += "<tr style='font-weight: bold; '>";
			this.loadedPreview += "<td>Index</td>";
			this.loadedPreview += "<td>Von-Station</td>";
			this.loadedPreview += "<td>An-Station</td>";
			this.loadedPreview += "<td>Lieferdatum</td>";
			this.loadedPreview += "<td>Kennzeichen</td>";
			this.loadedPreview += "<td>Produzent</td>";
			this.loadedPreview += "<td>Netto</td>";
			this.loadedPreview += "</tr>";
			for (let batschImport of this.loaded) {
				this.loadedPreview += "<tr>";
				this.loadedPreview += "<td>" + batschImport.index + "</td>";
				this.loadedPreview += "<td>" + batschImport.vonstation + "</td>";
				this.loadedPreview += "<td>" + batschImport.anstation + "</td>";
				this.loadedPreview += "<td>" + batschImport.lieferdatum + "</td>";
				this.loadedPreview += "<td>" + batschImport.kennzeichen + "</td>";
				this.loadedPreview += "<td>" + batschImport.produzent + "</td>";
				this.loadedPreview += "<td>" + batschImport.netto + "</td>";
				this.loadedPreview += "</td>";
			}
			this.loadedPreview += "</table>";
		});
	}
	
	importLoaded() {
		// const importLoop = function(_this, _index, callback1, callback2) {
		// 	callback1(_index, _this.loaded.length);
		// 	if (_index < _this.loaded.length && _index < 100) {
		// 		_this.data.set("/api/dynamic/henslbatschimporte", _this.loaded[_index]).subscribe(result => {
		// 			importLoop(_this, _index + 1, callback1, callback2);
		// 		});
		// 	} else {
		// 		callback2();
		// 	}
		// };
		// importLoop(this, 0, (index, max) => {
		// 	console.log(index, max);
		// }, () => {
		// 	
		// });
		this.loadedPreview = "";
		this.http.post<any>(
			"/html/hensl/scripts/add-to-imports.php", {
				loaded: this.loaded
			}, {
				headers: new HttpHeaders().append('Content-Type', 'application/json')
			}
		).subscribe(response => {
			this.refreshDataSource();
		});
	}
	
	waagscheinErstellen(_import: BatschImport) {
		let vonNummer = _import.vonstation;
		if (_import.produzent != '') {
			vonNummer = (+_import.produzent).toString();
		}
		forkJoin(
			this.data.single(() => new Station(), "select * from henslstationen where stationsnummer = '" + vonNummer + "'"),
			this.data.single(() => new Station(), "select * from henslstationen where stationsnummer = '" + _import.anstation + "'"),
			this.data.single(() => new Fahrzeug(), "select * from henslfahrzeuge where replace(kennzeichen, ' ', '') like replace('"
				+ _import.kennzeichen + "', ' ', '')"),
		).subscribe(result => {
			let vonStation = result[0];
			let nachStation = result[1];
			let fahrzeug = result[2];
			
			let dialogBuilder = new DialogBuilder(this.dialog);
			dialogBuilder.title = "Fehler";
			
			if (vonStation == null) {
				_import._temp = function() { return [ "...", function() { } ]; }
				dialogBuilder.text = "Von-Station wurde nicht gefunden.";
				dialogBuilder.info();
				return;
			}
			if (nachStation == null) {
				_import._temp = function() { return [ "...", function() { } ]; }
				dialogBuilder.text = "Ziel-Station wurde nicht gefunden.";
				dialogBuilder.info();
				return;
			}
			
			let preis = this.data.single<any>(null, "select tp.transportartid, tp.artikelid "
				+ "from hensltarife as t "
				+ "left join hensltarifpreise as tp on tp.tarifid = t.id "
				+ "join henslstationen as s1 on t.vonstationid = s1.id "
				+ "join henslstationen as s2 on t.nachstationid = s2.id "
				+ "where s1.id = " + vonStation.id + " and s2.id = " + nachStation.id
			).subscribe(tarif => {
				const waagschein = new Waagschein(this.data);
				if (tarif != null) {
					waagschein.transportartid = tarif.transportartid;
					waagschein.artikelid = tarif.artikelid;
				} else {
					_import._temp = function() { return [ "...", function() { } ]; }
					dialogBuilder.text = "Tarif wurde nicht gefunden.";
					dialogBuilder.info();
					return;
				}
				waagschein.vonstationid = vonStation.id;
				waagschein.nachstationid = nachStation.id;
				waagschein.fahrzeugid = fahrzeug ? fahrzeug.id : 0;
				waagschein.waagscheindatum = _import.lieferdatum.getDate();
				waagschein.menge = parseFloat(_import.netto.replace(',', '.'));
				waagschein._fraechterid = fahrzeug ? fahrzeug.fraechterid : 0;
				waagschein._updatePreise.subscribe(() => {
					Waagschein.neueWaagscheinnummer(this.data).subscribe(nummer => {
						waagschein.saveNummernkreis(nummer).subscribe(() => {
							this.data.set("/api/dynamic/henslwaagscheine", waagschein).subscribe(t => {
								this.data.execute(
									"update henslbatschimporte set waagscheinid = " + t.id + " where id = " + _import.id,
									"insert into henslwaagscheinattribute (waagscheinid, attributid, wert) values ("
										+ t.id + ", 17, '" + _import.belegnummer + "')",
									"insert into henslwaagscheinattribute (waagscheinid, attributid, wert) values ("
										+ t.id + ", 2, '" + _import.belegnummer.substring(4) + "')"
								).subscribe(() => {
									this.refreshDataSource();
								});
							});
						});
					});
				});
			});
			
			
			
			// waagschein.preisproeinheitineur = 0.00;
			// sondermautineur: number = 0.00;
			// preisgesamtineur: number = 0.00;
		});
		
		
		// let waagschein = new Waagschein();
		// waagschein.artikelid =  33;
		// waagschein.vonstationid = 
	}
}
