import { NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RootComponent } from 'root.component';

import {
	HomeComponent as HHome,
	TestComponent as HTest,
	EinstellungenComponent as HEinstellungen,
	ArtikelComponent as HArtikel,
	AttributeComponent as HAttribute,
	AuftraegeComponent as HAuftraege,
	AuswertungComponent as HAuswertung,
	ImporteComponent as HImporte,
	FraechterComponent as HFraechter,
	StationenComponent as HStationen,
	TarifeComponent as HTarife,
	TransportartenComponent as HTransportarten,
	WaagscheineComponent as HWaagscheine,
	FahrzeugtypenComponent as HFahrzeugtypen,
	RechnungenComponent as HRechnungen,
	RechnungsempfaengerComponent as HRechnungsempfaenger
} from 'app/hensl/components';

@NgModule({
	imports: [
		RouterModule.forRoot(
			// { path: "", redirectTo: "hensl", pathMatch: 'full' },
			// { path: "timetrack", redirectTo: "timetrack/login", pathMatch: 'full' },
			
			getRoutes(
				// [ "", RootComponent ],
				
				[ "hensl", HHome ],
				[ "hensl/test", HTest ],
				[ "hensl/einstellungen", HEinstellungen ]
				
			).concat(
			
				getRoutesWithID(
					[ "hensl/artikel", HArtikel ],
					[ "hensl/attribute", HAttribute ],
					[ "hensl/auftraege", HAuftraege ],
					[ "hensl/auswertung", HAuswertung ],
					[ "hensl/importe", HImporte ],
					[ "hensl/fahrzeugtypen", HFahrzeugtypen ],
					[ "hensl/fraechter", HFraechter ],
					[ "hensl/stationen", HStationen ],
					[ "hensl/tarife", HTarife ],
					[ "hensl/transportarten", HTransportarten ],
					[ "hensl/waagscheine", HWaagscheine ],
					[ "hensl/rechnungen", HRechnungen ],
					[ "hensl/rechnungsempfaenger", HRechnungsempfaenger ],
				)
			).concat([
				{ path: "", redirectTo: "hensl", pathMatch: 'full' }
			])
		)
	],
	exports: [ RouterModule ]
})
export class AppRoutingModule
{
	
}

export function getRoutes(...routes: any[]) {
	let result: any[] = [];
	for (let route of routes) {
		result.push({
			path: route[0],
			component: route[1],
			data: {
				state: route[0].split('/').join('')
			}
		});
	}
	return result;
}

export function getRoutesWithID(...routes: any[]) {
	let result: any[] = [];
	for (let route of routes) {
		let path: string = route[0];
		let component: Type<any> = route[1];
		let state: string = path.split('/').join('');
		result.push({
			path: path,
			component: component,
			data: { state: state }
		});
		result.push({
			path: path + "/:id",
			component: component,
			data: { state: state + "id" }
		});
	}
	return result;
}
