import { Component } from "@angular/core";
import { InputComponent } from "lib/components/input.component";

@Component({
	selector: 'checkbox',
	styles: [`
		mat-form-field {
			
		}
	`],
	template: `
		<mat-checkbox
			[formControl]="control"
			[_disabled]="!INPUT_enabled"
			[checked]="getValue()"
			(input)="setValue($event.target.checked)"
		>{{ INPUT_label }}</mat-checkbox>
	`
})
export class CheckBoxComponent extends InputComponent
{
	
}
