import { Component, Input, ViewContainerRef } from "@angular/core";
import { Utils } from "lib/utils/utils";
import { FormGroup } from "@angular/forms";
import { DataService } from "lib/services/data.service";
import { DialogBuilderInstance } from "lib/classes/dialog-builder-instance";
import { DialogBuilder } from "lib/classes/dialog-builder";
import { MatDialog } from "@angular/material";

@Component({
	selector: 'form-buttons',
	styles: [`
		button {
			margin: 1.2em 0.7em 0 0;
		}
	`],
	template: `
		<div style="margin-top: 1.8em; margin-bottom: 1em; ">
			<button *ngIf="INPUT_save" mat-raised-button (click)="save()" color="primary" [disabled]="!allowSave">Speichern</button>
			<button *ngIf="INPUT_cancel"  mat-raised-button (click)="parent.cancel()" type="button" [disabled]="!allowCancel">Abbrechen</button>
			<button *ngIf="INPUT_delete"  mat-raised-button (click)="_delete()" color="warn" type="button" [disabled]="!allowDelete">Löschen</button>
			<button *ngIf="INPUT_test"  mat-raised-button (click)="parent.test()" color="warn" type="button">Testen</button>
		</div>
	`
})
export class FormButtonsComponent
{
	@Input("save") INPUT_save: boolean = true;
	@Input("cancel") INPUT_cancel: boolean = true;
	@Input("delete") INPUT_delete: boolean = false;
	@Input("test") INPUT_test: boolean = false;
	
	saving: boolean = false;
	deleting: boolean = false;
	
	constructor(
		private container: ViewContainerRef,
		private data: DataService,
		private dialog: MatDialog
	) {
		
	}
	
	get parent() {
		return Utils.getParent(this.container);
	}
	
	get form(): FormGroup {
		return this.parent["form"];
	}
	
	save() {
		this.saving = true;
		this.parent.save(() => {
			this.saving = false;
		})
	}
	
	_delete() {
		this.deleting = true;
		this.parent._delete(() => {
			this.deleting = false;
		});
	}
	
	get allowCancel() {
		if (this.saving || this.deleting) {
			return false;
		}
		return true;
	}
	
	get allowSave() {
		if (!this.allowCancel) {
			return false;
		}
		if (this.data.loading) {
			return false;
		}
		if (!this.form.valid) {
			return false;
		}
		return true;
	}
	
	
	get allowDelete() {
		if (!this.allowCancel) {
			return false;
		}
		return true;
	}
}
