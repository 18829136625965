import { IUnique } from "lib/interfaces/i.unique";

export class Transportart implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	name: string = "";
	zuschlagproeinheitineur: number = 0;
	zuschlagproeinheitinprozent: number = 0;
	
	toString() {
		var result = this.name;
		if (this.zuschlagproeinheitineur != 0) {
			result += ", " + this.zuschlagproeinheitineur.toEuro(3)
		}
		if (this.zuschlagproeinheitinprozent != 0) {
			result += ", " + this.zuschlagproeinheitinprozent.toPercent(2)
		}
		return result;
	}
}
