import { Component } from "@angular/core";
import { InputComponent } from "lib/components/input.component";
import { isNumber } from "util";

@Component({
	selector: 'eurofield',
	styles: [`
		mat-form-field {
			margin: 0 1em 0 0;
			width: 100px;
		}
	`],
	template: `
		<mat-form-field>
			<input
				matInput
				#input
				type="number"
				[formControl]="control"
				[placeholder]="INPUT_label"
				[_disabled]="!INPUT_enabled"
				[value]="getValue()"
				(input)="setValue($event.target.value)"
				(change)="$event.target.value = convertValue(+$event.target.value)"
				(focusin)="selectText($event)"
				(keydown.enter)="tabToNext($event)"
				(keydown.ArrowLeft)="tabToPrevious($event)"
				(keydown.ArrowRight)="tabToNext($event)"
			>
			<span matPrefix>€&nbsp;</span>
		</mat-form-field>
	`
})
export class EuroFieldComponent extends InputComponent
{
	onLoad() {
		super.onLoad();
		this.control.setValue(this.getValue());
	}
	
	getValue() {
		let value = super.getValue();
		if (isNumber(value)) {
			value = this.convertValue(value);
		}
		return value;
	}
	
	setValue(newValue: any): void {
		super.setValue(+(newValue.split(',').join('.')));
	}
	
	convertValue(value: number) {
		value = Math.round(value * 1000) / 1000;
		value = +value.toFixed(3);
		return value;
	}
}
