import { Directive, ElementRef, Renderer } from '@angular/core';
@Directive({ selector: '[_focus]' })
export class FocusDirective
{
	constructor(renderer: Renderer, element: ElementRef) {
		FocusDirective.focus(renderer, element.nativeElement);
	}
	
	static focus(renderer: Renderer, element: any) {
		setTimeout(() => {
			renderer.invokeElementMethod(element, "focus");
		});
	}
}