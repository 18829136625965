import { DataService } from "lib/services/data.service";
import { isBoolean, isNumber } from "util";

export class Settings
{
	values: SettingsValue[] = [];
	
	constructor(public data: DataService) {
		
	}
	
	load(callback: () => void) {
		let unloaded = this.values.length;
		for (let value of this.values) {
			value.load(() => {
				unloaded--;
				if (unloaded == 0) {
					callback();
				}
			});
		}
	}
	
	get loaded() {
		for (let value of this.values){
			if (!value.loaded) {
				return false;
			}
		}
		return true;
	}
	
	add(name: string, defaultValue) {
		let value = new SettingsValue(this, name, defaultValue);
		this.values.push(value);
		return value;
	}
	
	get(name: string): SettingsValue {
		for (let value of this.values) {
			if (value.name == name) {
				return value;
			}
		}
		return null;
	}
	
	set(name: string, value: any): SettingsValue {
		let result = this.get(name);
		result.value = value;
		return result;
	}
}

export class SettingsValue
{
	settings: Settings;
	name: string;
	defaultValue: any;
	value: any;
	loaded: boolean;
	
	constructor(settings: Settings, name: string, defaultValue: any) {
		this.settings = settings;
		this.name = name;
		this.defaultValue = defaultValue;
		this.value = defaultValue;
		this.loaded = false;
	}
	
	load(callback?: () => void) {
		this.settings.data.single<any>(null, "select wert from hensleinstellungen where name = '"
			+ this.name + "'"
		).subscribe(result => {
			if (result) {
				this.value = result.wert;
				if (isBoolean(this.defaultValue)) {
					this.value = this.value == "true" ? true : false;
				} else if (isNumber(this.defaultValue)) {
					this.value = +this.value;
				}
			} else {
				this.value = this.defaultValue;
			}
			if (callback) {
				callback.apply(this);
			}
			this.loaded = true;
		});
	}
	
	save(callback?: () => void) {
		this.loaded = false;
		this.settings.data.query<string>(null, "select wert from hensleinstellungen where name = '"
			+ this.name + "'"
		).subscribe(einstellungen => {
			if (einstellungen.length > 0) {
				this.settings.data.query(null, "update hensleinstellungen set wert = '"
					+ this.value + "' where name = '" + this.name + "'"
				).subscribe(() => {
					this.load(callback);
				});
			} else {
				this.settings.data.query(null, "insert into hensleinstellungen (name, wert) values ('"
					+ this.name + "', '" + this.value + "')"
				).subscribe(() => {
					this.load(callback);
				});
			}
		});
	}
}
