import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'the-app',
	styles: [`
		.errors {
			position: fixed;
			z-index: 10000;
			top: 0;
			left: 0;
			right: 0;
			height: 3em;
			background: rgba(255, 255, 255, 0.2); 
		}
	`],
	template: `
		<root *ngIf="inRoot"></root>
		<hensl-root *ngIf="inHensl"></hensl-root>
	`
})
export class AppComponent
{
	constructor(
		private _location: Location,
	) {
		
	}
	
	get path() {
		return this._location.path();
	}
	
	get inRoot() {
		return this.path == "";
	}
	
	get inHensl() {
		return this.path.startsWith("/hensl");
	}
}
