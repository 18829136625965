import { IUnique } from "lib/interfaces/i.unique";
import { DataService } from "lib/services/data.service";

export class Station implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	_produzentheimstationsnummer = "";
	
	stationsnummer: string = "";
	stationsbezeichnung: string = "";
	adresse: string = "";
	plz: string = "";
	ort: string = "";
	landid: number = 0;
	produzentheimstationid = 0;
	distanzinkilometer = 0;
	
	constructor() {
		
	}
	
	private _toString: string;
	toString(): string {
		if (this._toString === undefined) {
			this._toString = this.stationsnummer + " " + this.stationsbezeichnung;
			if (this.ort) {
				this._toString += " (" + this.ort + ")";
			}
			if (this._produzentheimstationsnummer) {
				this._toString += " Station " + this._produzentheimstationsnummer;
			}
		}
		return this._toString;
	}
}
