import { Component, OnInit } from "@angular/core";
import { DataService } from "lib/services/data.service";

@Component({
	selector: "hensl-auswertung",
	styles: [`
		
	`],
	template: `
		<h2>Auswertung</h2>
		
		<mat-card>
			<div>
				<mat-form-field>
					<mat-select
						placeholder="Gespeicherte Abfrage"
						[(value)]="abfrage"
					>
						<mat-option
							*ngFor="let gespeicherteAbfrage of gespeicherteAbfragen"
							[value]="gespeicherteAbfrage"
						>{{ gespeicherteAbfrage.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div>
				<div style="padding-right: 1em; display: inline-block; ">
					<a (click)="vorschauOeffnen()">Vorschau öffnen</a>
				</div>
				<div style="padding-right: 1em; display: inline-block; ">
					<a>PDF erstellen</a>
				</div>
				<div style="padding-right: 1em; display: inline-block; ">
					<a (click)="csvHerunterladen()">CSV herunterladen</a>
				</div>
				<div style="padding-right: 1em; display: inline-block; ">
					<a (click)="goBack()">Zurück</a>
				</div>
			</div>
		</mat-card>
		
		<mat-card>
			<div>
				<mat-form-field>
					<mat-select placeholder="Tabelle" [(value)]="abfrage.table" (selectionChange)="fillColumns()">
						<mat-option value="henslartikelliste">Artikel</mat-option>
						<mat-option value="henslauftraege">Aufträge</mat-option>
						<mat-option value="henslfahrzeuge">Fahrzeuge</mat-option>
						<mat-option value="henslfraechterliste">Frächter</mat-option>
						<mat-option value="henslstationen">Stationen</mat-option>
						<mat-option value="hensltarife">Tarife</mat-option>
						<mat-option value="henslwaagscheine">Waagscheine</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			
			<div>
				<mat-form-field style="width: 100%; ">
					<textarea
						matInput
						placeholder='Spalten'
						[value]="abfrage.columns"
						(change)="abfrage.columns = $event.target.value"
						style="height: 5em; "
					></textarea>
				</mat-form-field>
			</div>
			
			<div>
				<mat-form-field style="width: 100%; ">
					<textarea
						matInput
						placeholder='Kopfzeile'
						[value]="abfrage.headers"
						(change)="abfrage.headers = $event.target.value"
						style="height: 3em; "
					></textarea>
				</mat-form-field>
			</div>
			
			<div>
				<mat-form-field style="width: 100%; ">
					<textarea
						matInput
						placeholder='Verbindungen'
						[value]="abfrage.joins"
						(change)="abfrage.joins = $event.target.value"
						style="height: 5em; "
					></textarea>
				</mat-form-field>
			</div>
			
			<div>
				<mat-form-field style="width: 100%; ">
					<textarea
						matInput
						placeholder='Filter'
						[value]="abfrage.where"
						(change)="abfrage.where = $event.target.value"
						style="height: 10em; "
					></textarea>
				</mat-form-field>
			</div>
			
			<div>
				<mat-form-field style="width: 100%; ">
					<textarea
						matInput
						placeholder='Sortierung'
						[value]="abfrage.order"
						(change)="abfrage.order = $event.target.value"
						style="height: 5em; "
					></textarea>
				</mat-form-field>
			</div>
		</mat-card>
	`
})
export class AuswertungComponent implements OnInit
{
	gespeicherteAbfragen: Abfrage[] = [];
	abfrage: Abfrage = new Abfrage();
	
	constructor(
		private data: DataService
	) {
		
	}
	
	ngOnInit() {
		this.data.query<any>(null, "select * from henslabfragen").subscribe(abfragen => {
			this.gespeicherteAbfragen = [];
			for (let abfrage of abfragen) {
				let temp = new Abfrage();
				temp.name = abfrage.name;
				temp.table = abfrage.tabelle;
				temp.columns = abfrage.spalten;
				temp.headers = abfrage.kopfzeile;
				temp.joins = abfrage.verbindungen;
				temp.where = abfrage.filter;
				temp.order = abfrage.sortierung;
				this.gespeicherteAbfragen.push(temp);
			}
			this.abfrage = this.gespeicherteAbfragen[0];
		});
	}

	fillColumns() {
		this.data.get("/api/system/tables/" + this.abfrage.table + "/columns").subscribe(columns => {
			this.abfrage.headers = (columns as any[]).join(", ");
			this.abfrage.columns = this.abfrage.headers.toLowerCase();
			this.abfrage.headers = "";
		});
	}

	vorschauOeffnen() {
		let sql: string = this.abfrage.toSQL();
		if (sql) {
			this.data.query<any>(null, sql).subscribe(rows => {
				
				var form = document.createElement("form");
				form.setAttribute("method", "post");
				form.setAttribute("action", "html/csv.php");
				form.setAttribute("target", "view");
				
				let field: HTMLInputElement;
				
				field = document.createElement("input"); 
				field.setAttribute("type", "hidden");
				field.setAttribute("name", "filename");
				field.setAttribute("value", this.abfrage.table);
				form.appendChild(field);
				
				field = document.createElement("input"); 
				field.setAttribute("type", "hidden");
				field.setAttribute("name", "filecontent");
				field.setAttribute("value", rows.toCSV());
				form.appendChild(field);
				
				field = document.createElement("input"); 
				field.setAttribute("type", "hidden");
				field.setAttribute("name", "preview");
				field.setAttribute("value", "1");
				form.appendChild(field);
				
				document.body.appendChild(form);
				
				window.open('', 'view');
				form.submit();
				
			});
		}
	}
	
	csvHerunterladen() {
		let sql: string = this.abfrage.toSQL();
		if (sql) {
			this.data.query<any>(null, sql).subscribe(rows => {
				
				var form = document.createElement("form");
				form.setAttribute("method", "post");
				form.setAttribute("action", "html/csv.php");

				let field: HTMLInputElement;
				
				field = document.createElement("input"); 
				field.setAttribute("type", "hidden");
				field.setAttribute("name", "filename");
				field.setAttribute("value", this.abfrage.table);
				form.appendChild(field);
				
				field = document.createElement("input"); 
				field.setAttribute("type", "hidden");
				field.setAttribute("name", "filecontent");
				field.setAttribute("value", rows.toCSV());
				form.appendChild(field);
				
				document.body.appendChild(form);
				
				form.submit();
				
			});
		}
	}
	
	goBack() {
		window.history.back();
	}
}

class Abfrage
{
	name: string = "";
	table: string = "henslartikelliste";
	headers: string = "";
	columns: string = "*";
	joins: string = "";
	where: string = "";
	order: string = "";
	
	toSQL(): string {
		if (!this.table) {
			return null;
		}
		let headers = "";
		if (this.headers) {
			let temp1 = this.headers.split(',');
			let temp2: string[] = [];
			for (let i = 0; i < temp1.length; i++) {
				temp2.push("'" + temp1[i].trim() + "' as c" + i);
			}
			headers = 'select ' + temp2.join(', ') + ' union all ';
		}
		let joins = "";
		if (this.joins) {
			joins = "" + this.joins + " ";
		}
		let where = "";
		if (this.where) {
			where = "where " + this.where + " ";
		}
		let order = "";
		if (this.order) {
			order = "order by " + this.order + " ";
		}
		let result = headers + "select " + this.columns + " from " + this.table + " " + joins + where + order;
		console.log(result);
		return result;
	}
}
