import { Component, OnInit } from "@angular/core";
import { SettingsService } from "app/hensl/services/settings.service";
import { Station } from "app/hensl/classes/station";
import { DataService } from "lib/services/data.service";
import { forkJoin } from "rxjs";
import { Transportart } from "app/hensl/classes/transportart";

@Component({
	selector: "hensl-einstellungen",
	styles: [`
		div.entry {
			margin: 0 0 1em 1em;
		}
	`],
	template: `
		<h2>Einstellungen</h2>
		
		<div class="entry">
			<mat-checkbox
				[checked]="settings.getValue('debug.showLoadingIntensity')"
				(input)="settings.setValue('debug.showLoadingIntensity', $event.target.checked)"
				[disabled]="!settings.isValueLoaded('debug.showLoadingIntensity')"
			>Ladeintensität anzeigen</mat-checkbox>
		</div>
		<div class="entry">
			<mat-checkbox
				[checked]="settings.getValue('debug.showAnimationState')"
				(input)="settings.setValue('debug.showAnimationState', $event.target.checked)"
				[disabled]="!settings.isValueLoaded('debug.showAnimationState')"
			>Animationsstatus anzeigen</mat-checkbox>
		</div>
		<div class="entry">
			<autocomplete
				label="Standard Station"
				value="defaultNachStation"
				[from]="stationen"
			></autocomplete>
		</div>
		<div class="entry">
			<autocomplete
				label="Standard Transportart"
				value="defaultTransportart"
				[from]="transportarten"
			></autocomplete>
		</div>
		<div class="entry">
			<div style="display: inline-block; padding: 0 0.5em 0 0; ">Maximale Anzahl "Heutige Aufträge"</div>
			<mat-form-field style="width: 60px; ">
				<input
					matInput
					type="number"
					placeholder='Anzahl'
					[value]="settings.getValue('view.maxHeutigeAuftraege')"
					(change)="settings.setValue('view.maxHeutigeAuftraege', $event.target.value)"
					[disabled]="!settings.isValueLoaded('view.maxHeutigeAuftraege')"
				>
			</mat-form-field>
		</div>
		<div class="entry">
			<mat-checkbox
				[checked]="settings.getValue('view.menuStaysOpen')"
				(input)="settings.setValue('view.menuStaysOpen', $event.target.checked)"
				[disabled]="!settings.isValueLoaded('view.menuStaysOpen')"
			>Menü offen lassen</mat-checkbox>
		</div>
		
		<div class="entry">
			<button mat-raised-button
				(click)="sendIssue()">Problem melden</button><br>
			<br>
			<a (click)="goBack()">Zurück</a>
		</div>
		
		<br>
		<br>
		
		<div class="entry">
			<mat-form-field style="width: 100%; ">
				<textarea style="height: 9.5em; color: blue; "
					matInput
					placeholder='Offene Punkte'
					[value]="settings.getValue('debug.notizenEntwicklungOffen')"
					(change)="settings.setValue('debug.notizenEntwicklungOffen', $event.target.value)"
					[disabled]="!settings.isValueLoaded('debug.notizenEntwicklungOffen')"
				></textarea>
			</mat-form-field>
			<mat-form-field style="width: calc(100%); ">
				<textarea style="height: 26.5em; color: green; "
					matInput
					placeholder='Abgeschlossene Entwicklung'
					[value]="settings.getValue('debug.notizenEntwicklungAbgeschlossen')"
					(change)="settings.setValue('debug.notizenEntwicklungAbgeschlossen', $event.target.value)"
					[disabled]="!settings.isValueLoaded('debug.notizenEntwicklungAbgeschlossen')"
				></textarea>
			</mat-form-field>
		</div>
	`
})
export class EinstellungenComponent implements OnInit
{
	stationen: Station[];
	transportarten: Transportart[];
	
	constructor(
		private settings: SettingsService,
		private data: DataService
	) {
		settings.load();
		
	}
	
	ngOnInit() {
		forkJoin(
			this.data.selectAll(() => new Station(), "henslstationen"),
			this.data.selectAll(() => new Transportart(), "hensltransportarten")
		).subscribe(result => {
			this.stationen = result[0];
			this.transportarten = result[1];
		});
	}
	
	sendIssue() {
		const dummy: () => void = undefined;
		dummy();
	}
	
	goBack() {
		window.history.back();
	}
	
	get defaultNachStation() {
		return this.settings.getValue('form.defaultNachStation');
	}
	
	set defaultNachStation(value: number) {
		this.settings.setValue('form.defaultNachStation', value);
	}
	
	get defaultTransportart() {
		return this.settings.getValue('form.defaultTransportart');
	}
	
	set defaultTransportart(value: number) {
		this.settings.setValue('form.defaultTransportart', value);
	}
}
