import { IUnique } from "lib/interfaces/i.unique";

export class Stationsfeld implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	stationid: number = 0;
	feldnummer: string = "";
	feldname: string = "";
	distanzinkilometer: number = 0;
}
