import { IUnique } from "lib/interfaces/i.unique";

export class Tarif implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	vonstationid: number = 0;
	nachstationid: number = 0;
}
