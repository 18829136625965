import { ErrorHandler, Injectable } from '@angular/core';
import { NotificationService } from 'lib/services/notification.service';
import { isString } from 'util';

@Injectable()
export class CustomErrorHandler implements ErrorHandler
{
	constructor(private service: NotificationService) {
		
	}
	
	handleError(error: any): void {
		if (isString(error.stack) && (error.stack as string).trim().startsWith("Error: ExpressionChangedAfterItHasBeenCheckedError: ")) {
			return;
		}
		if (error.message == "dummy is not a function") {
			error.message = "Unbekannter Fehler";
			error.stack = "Unbekannter Fehler";
		} else if (console && console.error) {
			console.error(error);
		}
		this.service.errors.push([error.message, error.stack]);
	}
}
