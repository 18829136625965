import { IUnique } from "lib/interfaces/i.unique";

export class Tarifpreis implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	tarifid: number = 0;
	artikelid: number = 0;
	transportartid: number = 0;
	grundpreisineur: number = 0.00;
	fraechterpreisabzuginprozent: number = 0.00;
	fraechterpreisabzugineur: number = 0.00;
	fraechterpreisineur: number = 0.00;
	sondermautineur: number = 0.00;
}
