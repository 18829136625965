import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { isUnique, IUnique } from "lib/interfaces/i.unique";
import { map } from "rxjs/operators";
import { isString } from "util";
import { MatAutocompleteTrigger } from "@angular/material";
import { isDefined } from "lib/utils/functions";
import { Utils } from "lib/utils/utils";

@Component({
	selector: 'autocomplete-text',
	styles: [`
		mat-form-field {
			margin: 0 1em 0 0;
			width: 240px;
		}
	`],
	template: `
		<mat-form-field style="width: 300px; ">
			<input matInput type="text"
				#input
				#trigger="matAutocompleteTrigger"
				[matAutocomplete]="auto"
				[value]="getValue()"
				(input)="onInput($event.target.value)"
				(change)="onChange($event)"
				autocomplete="off"
				(focusin)="onFocus($event)"
				(keydown.enter)="onKeyDownEnter($event)"
			>
			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
				<mat-option *ngFor="let option of filteredOptions" [value]="option"
					(onSelectionChange)="_setValue($event.source.value, true); onInput2($event.source.value)"
				>
					{{ option.toString() }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	`
})
export class AutoCompleteTextComponent
{
	@Input() value: any;
	@Input() from: any[];
	filteredOptions: any[];
	
	@Output('_onInput2')
	OUTPUT_onInput2 = new EventEmitter<any>();
	
	@ViewChild(MatAutocompleteTrigger)
	matAutocompleteTrigger: MatAutocompleteTrigger;
	
	@ViewChild("input") inputField;
	
	onInput(value) {
		this._setValue(value, true);
		this.filteredOptions = this.getFilteredOptions(this.from, this.value);
	}
	
	onInput2(value) {
		this.onInput(value);
		this.OUTPUT_onInput2.emit(value);
	}
	
	onChange(e) {
		this._setValue(e.target.value, false);
	}
	
	updateFilteredOptions(from :any[], value: any) {
		this.filteredOptions = this.getFilteredOptions(from, value);
	}
	
	getFilteredOptions(from: any[], value: any) {
		if (isDefined(from) && isString(value)) {
			value = value.toLowerCase();
			return from.filter(option => {
				const optionString = option.toString().toLowerCase();
				return optionString.indexOf(value) !== -1;
			});
		}
		return [];
	}
	
	onFocus(e) {
		if (e) {
			e.preventDefault();
			e.target.select();
		}
	}
	
	onBlur(e) {
		this.updateValidity();
	}
	
	getValue(): any {
		let value = this.value;
		return value;
	}
	
	private _setValue(value: any, force: boolean): void {
		this.value = value;
	}
	
	onKeyDownEnter(e)
	{
		Utils.tabToNext(this.inputField.nativeElement, e);
	}
}
