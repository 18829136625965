
Array.prototype.toCSV = function(): string {
	let csv = "";
	let rows: any[] = this;
	for (let row of rows) {
		let values: string[] = Object.values<any>(row);
		for (let i in values) {
			if (values[i] == null) {
				values[i] = "";
			} else {
				values[i] = values[i].toString().split('"').join("'");
			}
		}
		csv += '"' + values.join('";"') + '"\r\n';
	}
	return csv;
}

Array.prototype.add = function<T>(item: T): T {
	this.push(item);
	return item;
}

Array.prototype.remove = function<T>(item: T): T {
	this.splice(this.indexOf(item), 1);
	return item;
}
