import {
	Renderer,
	ElementRef
} from "@angular/core";

import { isBoolean, isNumber, isString } from "util";

export function invoke(renderer: Renderer, element: ElementRef, method: string, args?: any) {
	setTimeout(() => {
		renderer.invokeElementMethod(element.nativeElement, method, args);
	}, 0);
}

export function isDefined(value: any) {
	return typeof value !== 'undefined';
}

export function call(callback: any, ...args: any[]) {
	if (callback) {
		callback(...args);
	}
}

export function cast<T>(item: any, newT: (item) => T) {
	if (item == null) {
		return null;
	}
	let newItem: T = newT(item);
	for (let key in item) {
		if (isBoolean(newItem[key]) && isNumber(item[key])) {
			newItem[key] = (item[key] != 0) as any;
		} else {
			newItem[key] = item[key];
		}
	}
	return newItem;
}

export function castArray<T>(array: any[], newT: (item) => T) {
	let result: T[] = [];
	for (let item of array) {
		result.push(cast(item, newT));
	}
	return result;
}

let _timeoutHandles: any = {};
let _intervalHandles: any = {};

export function delay(handleName: string, func: () => void, delay: number) {
	const _func = func;
	let result = setTimeout(_func, delay);
	if (handleName) {
		clear(handleName);
		_timeoutHandles[handleName] = result;
	}
	return result;
}

export function interval(handleName: string, func: () => void, delay: number) {
	const _func = func;
	let result = setInterval(_func, delay);
	if (handleName) {
		clear(handleName);
		_intervalHandles[handleName] = result;
	}
	return result;
}

export function clear(handleName: string) {
	if (_timeoutHandles[handleName]) {
		clearTimeout(_timeoutHandles[handleName]);
		delete _timeoutHandles[handleName];
	}
	if (_intervalHandles[handleName]) {
		clearInterval(_intervalHandles[handleName]);
		delete _intervalHandles[handleName];
	}
}

export function merge(...objects: any[]) {
	return Object.assign({ }, ...objects);
}
