import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatRadioButton, MatButton } from "@angular/material";

@Component({
	selector: 'custom-dialog',
	styles: [`
		h2, mat-dialog-content, mat-dialog-actions {
			font-family: 'Roboto', sans-serif;
			overflow: visible;
		}
		.dialog-radio-button {
			display: block;
			padding: 0.2em;
		}
		:host ::ng-deep .mat-form-field-infix {
			padding: 0.5em 0 1em;
		}
	`],
	template: `
		<h2 mat-dialog-title>{{ title }}</h2>
		<mat-dialog-content style="margin-bottom: 1em; ">
			<div [innerHTML]="text | html"></div>
			<mat-radio-group *ngIf="options.length > 0" (change)="selectedOption = $event.value" (keydown.enter)="apply()">
				<mat-radio-button checked #firstOption
					class="dialog-radio-button"
					[value]='options[0]'
				>{{ options[0].toString().split(" ").join("&nbsp;") }}</mat-radio-button>
				<span *ngFor="let option of options; index as i">
					<mat-radio-button *ngIf="i > 0"
						class="dialog-radio-button"
						[value]='option'
					>{{ option.toString().split(" ").join("&nbsp;") }}</mat-radio-button>
				</span>
			</mat-radio-group>
			<mat-form-field *ngIf="inputLabel" style="width: 100%; " appearance="outline">
				<textarea
					matInput
					placeholder='{{ inputLabel }}'
					style="height: 3.3em; "
					(change)="inputValue = $event.target.value"
				></textarea>
			</mat-form-field>
		</mat-dialog-content>
		<mat-dialog-actions style="padding-bottom: 1em; display: block; text-align: right; ">
			<span *ngIf="question && options.length > 0">
				<button mat-button [mat-dialog-close]="false">Abbrechen</button>
				<button #applyButton
					mat-flat-button [mat-dialog-close]="true"
					style="margin-left: 0.7em; "
				>Übernehmen</button>
			</span>
			<span *ngIf="question && options.length == 0">
				<button mat-button [mat-dialog-close]="false">Nein</button>
				<button mat-flat-button [mat-dialog-close]="true" style="margin-left: 0.7em; ">Ja</button>
			</span>
			<span *ngIf="!question">
				<button mat-button [mat-dialog-close]="false">Schliessen</button>
			</span>
		</mat-dialog-actions>
	`
})
export class DialogComponent implements OnInit, AfterViewInit
{
	@ViewChild("firstOption") firstOption: MatRadioButton;
	@ViewChild("applyButton") applyButton: MatButton;
	
	title: string = "";
	text: string = "";
	question: boolean = false;
	options: any[] = [];
	selectedOption: any;
	inputLabel: string = null;
	inputValue: string = null;
	
	ngOnInit(): void {
		if (this.options.length > 0) {
			this.selectedOption = this.options[0];
		}
	}
	
	ngAfterViewInit(): void {
		if (this.firstOption) {
			this.firstOption.focus();
		}
	}
	
	apply() {
		this.applyButton._elementRef.nativeElement.click();
	}
}
