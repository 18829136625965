import {
	ArtikelComponent,
	AttributeComponent,
	AuftraegeComponent,
	AuswertungComponent,
	CheckTagListComponent,
	EinstellungenComponent,
	FahrzeugtypenComponent,
	FraechterComponent,
	HomeComponent,
	RootComponent,
	StationenComponent,
	TarifeComponent,
	TestComponent,
	TransportartenComponent,
	WaagscheineComponent,
	ImporteComponent,
	RechnungenComponent,
	RechnungsempfaengerComponent
}  from './components';

export function getAllComponents() {
	return [
		ArtikelComponent,
		AttributeComponent,
		AuftraegeComponent,
		AuswertungComponent,
		CheckTagListComponent,
		EinstellungenComponent,
		FahrzeugtypenComponent,
		FraechterComponent,
		HomeComponent,
		RootComponent,
		StationenComponent,
		TarifeComponent,
		TestComponent,
		TransportartenComponent,
		WaagscheineComponent,
		ImporteComponent,
		RechnungenComponent,
		RechnungsempfaengerComponent
	];
}