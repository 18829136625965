import { IUnique } from "lib/interfaces/i.unique";

export class Artikel implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	artikelnummer: string = "";
	artikelbezeichnung: string = "";
	einheit: string = "";
	
	toString() {
		return this.artikelnummer + " " + this.artikelbezeichnung;
	}
}
