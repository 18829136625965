import { Component } from '@angular/core';

@Component({
	selector: 'root',
	template: `
		<h2>Anwendungen</h2>
		<ul>
			<li><a href="/hensl">HENSL Transport GesmbH</a></li>
			<li><a href="/test">Test</a></li>
			<li><a href="/timetrack">TimeTrack</a></li>
			<li><a href="/raspi-admin">Raspberry PI Administration</a></li>
		</ul>
	`
})
export class RootComponent
{
	
}
