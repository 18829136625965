import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { isUnique, IUnique } from "lib/interfaces/i.unique";
import { InputComponent } from "lib/components/input.component";
import { map } from "rxjs/operators";
import { isString, isNumber } from "util";
import { MatAutocompleteTrigger } from "@angular/material";
import { isDefined } from "lib/utils/functions";

@Component({
	selector: 'autocomplete',
	styles: [`
		mat-form-field {
			margin: 0 1em 0 0;
			width: 240px;
		}
	`],
	template: `
		<mat-form-field
			[class.wave-input-field]="INPUT_wave"
			[class.no-underline]="INPUT_wave"
			style="width: 300px; "
		>
			<input
				matInput
				#input
				#trigger="matAutocompleteTrigger"
				[formControl]="control"
				[placeholder]="INPUT_label"
				[_disabled]="!INPUT_enabled"
				[matAutocomplete]="auto"
				aria-label="Number"
				[value]="getValue()"
				(input)="onInput($event)"
				(change)="_setValue($event.target.value, false)"
				(focus)="onFocusSpecial($event)"
				(focusin)="onFocus($event)"
				(blur)="onBlur($event)"
				(keydown.enter)="onKeyDownEnter($event)"
				(keydown.ArrowLeft)="onKeyDownArrowLeft($event)"
				(keydown.ArrowRight)="onKeyDownArrowRight($event)"
				autocomplete="off"
			>
			<div *ngIf="INPUT_wave" class="wave-input-svg-container">
				<svg preserveAspectRatio="none" viewBox="0 0 1200 60" width="300%" height="100%" class="wave-input-svg">
					<path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
				</svg>
			</div>
			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
				<mat-option *ngFor="let option of filteredOptions" [value]="option"
					(onSelectionChange)="_setValue($event.source.value, true); tabToNextOnFocus(); "
				>
					{{ option.toString() }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	`
})
export class AutoCompleteComponent extends InputComponent
{
	@Input() from: any[];
	filteredOptions: any[];
	
	@Output('_onKeyDownEnter')
	OUTPUT_onKeyDownEnter = new EventEmitter();
	
	@Output('_onKeyDownArrowLeft')
	OUTPUT_onKeyDownArrowLeft = new EventEmitter();
	
	@Output('_onKeyDownArrowRight')
	OUTPUT_onKeyDownArrowRight = new EventEmitter();
	
	@ViewChild(MatAutocompleteTrigger)
	matAutocompleteTrigger: MatAutocompleteTrigger;
	
	private _tabToNextOnFocus: boolean = false;
	
	onInput(e) {
		if (e.target.value === '') {
			this._setValue(0, true);
		} else {
			this.control.setErrors({ 'notANumber': true });
		}
	}
	
	ngOnInit() {
		super.ngOnInit();
		this.control.valueChanges.pipe(
			map(value => this.getFilteredOptions(this.from, value))
		).subscribe(result => {
			this.filteredOptions = result;
		});
	}
	
	updateFilteredOptions(from :any[], value: any) {
		this.filteredOptions = this.getFilteredOptions(from, value);
	}
	
	getFilteredOptions(from: any[], value: any) {
		if (isDefined(from) && isString(value)) {
			value = value.toLowerCase();
			return from.filter(option => {
				const optionString = option.toString().toLowerCase();
				return optionString.indexOf(value) !== -1;
			});
		}
		return [];
	}
	
	onFocus(e) {
		this.selectText(e);
	}
	
	onBlur(e) {
		this._tabToNextOnFocus = false;
		this.updateValidity();
	}
	
	getValue(): any {
		let value = super.getValue();
		if (isDefined(this.from)) {
			for (let item of this.from) {
				if (item.id == value) {
					return item.toString();
				}
			};
		}
		return "";
	}
	
	private _setValue(value: any, force: boolean): void {
		if (isUnique(value)) {
			super.setValue((value as IUnique).id);
		} else if (value === '') {
			super.setValue(0);
		} else if (force) {
			super.setValue(value);
		}
	}
	
	onFocusSpecial(e) {
		if (this._tabToNextOnFocus) {
			this.onKeyDownEnter(e);
		}
	}
	
	tabToNextOnFocus() {
		this._tabToNextOnFocus = true;
	}
	
	onKeyDownEnter(e)
	{
		this.tabToNext(e);
		this.OUTPUT_onKeyDownEnter.emit();
	}
	
	onKeyDownArrowLeft(e)
	{
		this.tabToPrevious(e);
		this.OUTPUT_onKeyDownArrowLeft.emit();
	}
	
	onKeyDownArrowRight(e)
	{
		this.tabToNext(e);
		this.OUTPUT_onKeyDownArrowRight.emit();
	}
}
