
Number.prototype.getDate = function(): Date {
	if (!isNaN(this)) {
		return new Date(this);
	}
	return null;
}

Number.prototype.toEuro = function(decimals: number): string {
	let pow = Math.pow(10.0, decimals);
	return "€ " + (Math.round(this * pow) / pow).toFixed(decimals);
}

Number.prototype.toPercent = function(decimals: number): string {
	let pow = Math.pow(10.0, decimals);
	return (Math.round(this * pow) / pow).toFixed(decimals) + " %";
}
