import { NativeDateAdapter } from '@angular/material';
import { isString } from 'util';

export class DateFormat extends NativeDateAdapter
{
	useUtcForDisplay = true;
	parse(value: any): Date | null {
		if (isString(value)) {
			return value.getDate();
		}
		return value;
	}
}
