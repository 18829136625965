import { IUnique } from "lib/interfaces/i.unique";

export class Rechnungsempfaenger implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	name: string = "";
	adresse: string = "";
	uid: string = "";
	
	toString() {
		var result = this.name;
		return result;
	}
}
