/// <reference path="lib/utils/global.d.ts" />

import 'lib/utils/extensions/array.extensions';
import 'lib/utils/extensions/date.extensions';
import 'lib/utils/extensions/number.extensions';
import 'lib/utils/extensions/string.extensions';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
