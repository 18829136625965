import { MatDialog } from "@angular/material";
import { ExtendedObservable } from "lib/classes/extended-observable";
import { DialogComponent } from "lib/dialog.component";
import { DialogBuilderInstance } from "lib/classes/dialog-builder-instance";

export class DialogBuilder
{
	title: string = "";
	text: string = "";
	width: string = null;
	
	options: any[];
	inputLabel: string;
	
	constructor(private dialog: MatDialog, title?: string, text?: string) {
		this.set(title, text);
	}
	
	set(title?: string, text?: string) {
		if (title) {
			this.title = title;
		}
		if (text) {
			this.text = text;
		}
		this.options = [];
		this.inputLabel = null;
	}
	
	info(): DialogBuilderInstance {
		return this._open(false);
	}
	
	ask(): DialogBuilderInstance {
		return this._open(true);
	}
	
	private _open(question: boolean): DialogBuilderInstance {
		const reference = this.dialog.open(DialogComponent, {
			width: this.width,
			autoFocus: false,
			disableClose: question
		});
		
		const instance = reference.componentInstance;
		instance.title = this.title;
		instance.text = this.text;
		instance.options = this.options;
		instance.question = question;
		instance.inputLabel = this.inputLabel;
		
		const dialog = ExtendedObservable.from<boolean>(reference.afterClosed());
		
		return new DialogBuilderInstance(instance, dialog);
	}
}
