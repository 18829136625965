import { IUnique } from "lib/interfaces/i.unique";

export class Fahrzeugtyp implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	name: string = "";
	
	toString() {
		return this.name;
	}
}
