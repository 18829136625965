import { DialogComponent } from "lib/dialog.component";
import { ExtendedObservable } from "lib/classes/extended-observable";

export class DialogBuilderInstance
{
	constructor(
		public instance: DialogComponent,
		public dialog: ExtendedObservable<boolean>)
	{
		
	}
}
