import { IUnique } from "lib/interfaces/i.unique";
import { Fahrzeug } from "app/hensl/classes/fahrzeug";
import { Auftrag } from "app/hensl/classes/auftrag";
import { Utils } from "lib/utils/utils";
import { Fraechter } from "app/hensl/classes/fraechter";
import { DataService } from "lib/services/data.service";
import { ExtendedObservable } from "lib/classes/extended-observable";
import { Tarifpreis } from "app/hensl/classes/tarifpreis";
import { Transportart } from "app/hensl/classes/transportart";
import { Artikel } from "app/hensl/classes/artikel";

export class Waagschein implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	auftragid: number = 0;
	rechnungid: number = 0;
	verrechnet: boolean = false;
	
	waagscheinnummer: string = "";
	vonstationid: number = 0;
	nachstationid: number = 0;
	fahrerid: number = 0;
	fahrzeugid: number = 0;
	anlagedatum: Date = new Date();
	aenderungsdatum: Date = new Date();
	ladezeit: string = "00:00";
	ladezeitende: string = "00:00";
	entladezeit: string = "00:00";
	entladezeitende: string = "00:00";
	mautineur: number = 0.00;
	transportartid: number = 0;
	artikelid: number = 0;
	menge: number = 0.00;
	fuhrenanzahl: number = 1;
	preisproeinheitineur: number = 0.00;
	sondermautineur: number = 0.00;
	preisgesamtineur: number = 0.00;
	waagscheindatum: Date = new Date();
	rechnungsempfaengerid: number = 0;
	rechnungsadresse: string = "";
	grundpreisproeinheitineur: number = 0.00;
	grundpreisgesamtineur: number = 0.00;
	energiekostenzuschlaginprozent: number = 0.00;
	
	_preisproeinheitoriginal: number = 0.00;
	_zuschlag: number = 0.00;
	_zuschlagprozent: number = 0.00;
	_fraechterid: number = 0;
	
	constructor(private _data: DataService) {
		
	}
	
	static selectAll(data: DataService, max: number): ExtendedObservable<any[]> {
		return data.query<any>(null, `
			select ` + (max > 0 ? "top " + max : "") + `
				a.*,
				concat(s1.stationsnummer, ' ', s1.stationsbezeichnung) as von,
				concat(s2.stationsnummer, ' ', s2.stationsbezeichnung) as nach,
				fz.kennzeichen as fahrzeug,
				concat(art.artikelnummer, ' ', art. artikelbezeichnung) as artikel
			from henslwaagscheine as a
			left join henslstationen as s1 on s1.id = a.vonstationid
			left join henslstationen as s2 on s2.id = a.nachstationid
			left join henslfahrzeuge as fz on fz.id = a.fahrzeugid
			left join henslartikelliste as art on art.id = a.artikelid
			` + (max > 0 ? "order by id desc" : "") + `
		`).convert(values => {
			for (let value of values) {
				value.strecke = value.von + " ► " + value.nach;
			}
			return values;
		});
	}
	
	static select(data: DataService, id: number): ExtendedObservable<Waagschein> {
		return data.single(() => new Waagschein(data), `
			select top 1 a.*, fr.id as _fraechterid
			from henslwaagscheine as a
			left join henslfahrzeuge as f on f.id = a.fahrzeugid
			left join henslfraechterliste as fr on fr.id = f.fraechterid
			where a.id = ` + id
		);
	}
	
	static neueWaagscheinnummer(data: DataService): ExtendedObservable<any> {
		return data.single<any>(null, `
			select id, (nummer + 1) as nummer
			from henslnummernkreise
			where gruppe = 'Waagschein'
			and jahr = ` + Utils.getYear()
		);
	}
	
	get _fahrzeuge(): ExtendedObservable<Fahrzeug[]> {
		return this._data.query(() => new Fahrzeug(), "select henslfahrzeuge.* from henslfraechterliste "
			+ "join henslfahrzeuge on henslfahrzeuge.fraechterid <> 0 and henslfahrzeuge.fraechterid = henslfraechterliste.id "
			+ "where henslfraechterliste.id = " + this._fraechterid);
	}
	 
	get _preis(): ExtendedObservable<Tarifpreis> {
		return this._data.single(() => new Tarifpreis(), "select top 1 hensltarifpreise.* from hensltarifpreise "
			+ "join hensltarife on hensltarife.id = hensltarifpreise.tarifid "
			+ "where vonstationid = " + this.vonstationid + " "
			+ "and nachstationid = " + this.nachstationid + " "
			+ "and artikelid = " + this.artikelid + " "
			+ "and transportartid = " + this.transportartid + " "
		);
	}
	
	saveNummernkreis(nummernkreis: any): ExtendedObservable<void> {
		let neueNummer = nummernkreis.nummer;
		return this._data.set("/api/dynamic/henslnummernkreise", {
			id: nummernkreis.id,
			nummer: neueNummer
		}).convert(result => {
			this.waagscheinnummer = Utils.getYear() + "-" + neueNummer;
			return result;
		});
	}
	
	get _auftrag(): ExtendedObservable<any> {
		return this._data.single<any>(null,
			"select id, auftragsnummer from henslauftraege where id = "
				+ this.auftragid
		);
	}
	
	get _moeglicheAuftraege(): ExtendedObservable<Auftrag[]> {
		let anlagedatum = this.anlagedatum.toCustomDateString();
		let waagscheindatum = this.waagscheindatum.toCustomDateString();
		return this._data.query(() => new Auftrag(this._data), `
			select a.id, a.auftragsnummer, a.fahrzeugid, a.artikelid, a.transportartid,
				fr.id as _fraechterid,
				a.preisproeinheitineur, a.menge, a.sondermautineur, a.preisgesamtineur,
				a.rechnungsempfaengerid, a.rechnungsadresse
			from henslauftraege as a
			left join henslfahrzeuge as f on f.id = a.fahrzeugid
			left join henslfraechterliste as fr on fr.id = f.fraechterid
			left join henslwaagscheine as w on w.auftragid = a.id
			where (
				(a.hatmehrerewaagscheine = 1 or w.id is null) and
				(
					cast('` + anlagedatum + `' as date)     = cast(a.anlagedatum as date) or
					cast('` + anlagedatum + `' as date)     between dateadd(day, -14, cast(a.planmaessigesstartdatum as date))
						and dateadd(day, 14, cast(a.planmaessigesenddatum as date)) or
					cast('` + waagscheindatum + `' as date) = cast(a.anlagedatum as date) or
					cast('` + waagscheindatum + `' as date) between dateadd(day, -14, cast(a.planmaessigesstartdatum as date))
						and dateadd(day, 14, cast(a.planmaessigesenddatum as date))
				)
				and a.vonstationid = ` + this.vonstationid + `
				and a.nachstationid = ` + this.nachstationid + `
			)
		`);
	}
	
	get _rechnung(): ExtendedObservable<any> {
		return this._data.single<any>(null,
			"select id, rechnungsnummer from henslrechnungen where id = "
				+ this.rechnungid
		);
	}
	
	get _fraechter(): ExtendedObservable<Fraechter> {
		return this._data.selectAll(
			() => new Fraechter(), "henslfraechterliste"
		).convert(
			fraechterliste => {
				for (let fraechter of fraechterliste) {
					if (fraechter.id == this._fraechterid) {
						return fraechter;
					}
				}
				return null as Fraechter;
			}
		);
	}
	
	get _artikel(): ExtendedObservable<Artikel> {
		if (this.artikelid > 0) {
			return this._data.single(() => new Artikel(),
				"select * from henslartikelliste where id = " + this.artikelid
			);
		}
		return ExtendedObservable.of<Artikel>();
	}
	
	get _transportart(): ExtendedObservable<Transportart> {
		if (this.transportartid > 0) {
			return this._data.single(() => new Transportart(),
				"select * from hensltransportarten where id = " + this.transportartid
			);
		}
		return ExtendedObservable.of<Transportart>(null);
	}
	
	get _updatePreise(): ExtendedObservable<void> {
		this.grundpreisproeinheitineur = 0;
		this.preisproeinheitineur = 0;
		this._preisproeinheitoriginal = 0;
		this._zuschlag = 0;
		this._zuschlagprozent = 0;
		this.sondermautineur = 0;
		return this._transportart.exchange(
			transport => {
				if (transport) {
					this._zuschlag += transport.zuschlagproeinheitineur;
					this._zuschlagprozent += transport.zuschlagproeinheitinprozent;
				}
				return this._preis;
			}
		).exchange(
			preis => {
				if (preis != null) {
					this.sondermautineur += +preis.sondermautineur;
					return this._fraechter.exchange(fraechter => {
						
						if (fraechter && fraechter.hatfraechterpreis) {
							this._preisproeinheitoriginal += +preis.fraechterpreisineur;
							this.preisproeinheitineur += +preis.fraechterpreisineur;
						} else {
							this._preisproeinheitoriginal += +preis.grundpreisineur;
							this.preisproeinheitineur += +preis.grundpreisineur;
						}
						this.preisproeinheitineur += this.preisproeinheitineur / 100.00 * this._zuschlagprozent;
						this.preisproeinheitineur += this._zuschlag;
						
						this.preisgesamtineur = this.preisproeinheitineur * this.menge + this.sondermautineur;
						this.preisgesamtineur *= this.fuhrenanzahl;
						
						this.grundpreisproeinheitineur += +preis.grundpreisineur;
						this.grundpreisproeinheitineur += this.grundpreisproeinheitineur / 100.00 * this._zuschlagprozent;
						this.grundpreisproeinheitineur += this._zuschlag;
						
						this.grundpreisgesamtineur = this.grundpreisproeinheitineur * this.menge + this.sondermautineur;
						this.grundpreisgesamtineur *= this.fuhrenanzahl;
						
						return ExtendedObservable.of(null);
					});
				}
				return ExtendedObservable.of(null);
			}
		);
	}
}
