import { Component, Injector, ViewChild } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Auftrag } from 'app/hensl/classes/auftrag';
import { Station } from 'app/hensl/classes/station';
import { Fahrzeug } from 'app/hensl/classes/fahrzeug';
import { Artikel } from 'app/hensl/classes/artikel';
import { Rechnungsempfaenger } from 'app/hensl/classes/rechnungsempfaenger';
import { forkJoin } from 'rxjs';
import { Fraechter } from 'app/hensl/classes/fraechter';
import { CheckTagListComponent } from 'app/hensl/components/checktaglist.component';
import { AutoCompleteComponent } from 'lib/components/autocomplete.component';
import { Waagschein } from 'app/hensl/classes/waagschein';
import { Utils } from 'lib/utils/utils';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { isNumber } from 'util';
import { SettingsService } from 'app/hensl/services/settings.service';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { merge } from 'lib/utils/functions';
import { Transportart } from 'app/hensl/classes/transportart';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-auftraege',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<div *ngIf="!id" style="float: right; margin-top: 1em; ">
			<div style="float: left; ">
				<mat-icon>calendar_today</mat-icon>
			</div>
			<div style="float: right; margin-top: .35em; margin-left: .12em; ">
				<a href="https://{{ getInternalHostname() }}/calendar" target="_blank">Kalender öffnen</a>
			</div>
		</div>
		<h2>Aufträge</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'auftragsnummer', label: 'Auftragsnummer' },
					{ name: 'anlagedatum', label: 'Datum', type: 'date' },
					{ name: 'strecke', label: 'Strecke' },
					{ name: 'fahrzeug', label: 'Fahrzeug' },
					{ name: 'artikel', label: 'Artikel' },
					{ name: 'preisproeinheitineur', label: 'Preis / Einheit', type: 'euro' },
					{ name: 'menge', label: 'Menge' },
					{ name: 'preisgesamtineur', label: 'Preis (Gesamt)', type: 'euro' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neuer Auftrag</button>
		</div>
		<form [formGroup]="form" *ngIf="id" style="width: 700px; max-width: 100%; float: left; margin-bottom: 2em; margin-right: 2em; ">
			<mat-card>
				<mat-card-subtitle>Kopfbereich</mat-card-subtitle>
				<mat-card-content>
					<div>
						<textfield
							label="Auftragsnummer"
							value="current.auftragsnummer"
							[enabled]="false"
						></textfield>
						<datefield
							label="Anlagedatum"
							value="current.anlagedatum"
							[enabled]="false"
						></datefield>
					</div>
					<div>
						<datefield _focus
							width="150px"
							label="Planmäßiges Startdatum"
							value="current.planmaessigesstartdatum"
						></datefield>
						<datefield
							width="150px"
							label="Planmäßiges Enddatum"
							value="current.planmaessigesenddatum"
						></datefield>
						<timefield
							label="Ladezeit"
							value="current.planmaessigeladezeit"
						></timefield>
						<timefield
							label="Entladezeit"
							value="current.planmaessigeentladezeit"
						></timefield>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Rechnungsempfänger</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							#autocompleteRechnungsempfaenger
							label="Rechnungsempfänger"
							value="current.rechnungsempfaengerid"
							[from]="rechnungsempfaenger"
							[wave]="true"
							(_change)="updateRechnungsadresse()"
							(_onKeyDownEnter)="autocompleteRechnungsempfaenger.tabTo($event, 2)"
							(_onKeyDownArrowRight)="autocompleteRechnungsempfaenger.tabTo($event, 2)"
						></autocomplete>
					</div>
					<div>
						<mat-form-field style="width: 100%; ">
							<textarea
								matInput
								placeholder='Rechnungsadresse'
								[value]="current.rechnungsadresse"
								(change)="current.rechnungsadresse = $event.target.value"
								style="height: 5em; "
							></textarea>
						</mat-form-field>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Strecke</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							#autocompleteVonStation
							label="Von Station"
							value="current.vonstationid"
							[from]="stationen"
							(_change)="updatePreise($event)"
							[wave]="true"
						></autocomplete>
						<autocomplete
							label="Nach Station"
							value="current.nachstationid"
							[from]="stationen"
							(_change)="updatePreise($event)"
							[wave]="true"
						></autocomplete>
					</div>
					<div>
						<autocomplete
							label="Frächter"
							value="current._fraechterid"
							[from]="fraechter"
							(_change)="updateFahrzeuge(true)"
							[wave]="true"
						></autocomplete>
						<autocomplete
							#autocompleteFahrzeuge
							label="Fahrzeug"
							value="current.fahrzeugid"
							[from]="fahrzeuge"
							(_change)="updatePreise($event)"
							[wave]="true"
						></autocomplete>
					</div>
					<div>
						<eurofield
							label="Maut"
							value="current.mautineur"
						></eurofield>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Transport</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							label="Artikel"
							value="current.artikelid"
							[from]="artikel"
							(_change)="updatePreise($event); updateArtikelEinheit()"
							[wave]="true"
						></autocomplete>
						<autocomplete
							label="Transportart"
							value="current.transportartid"
							[from]="transportarten"
							(_change)="updatePreise($event)"
							[wave]="true"
						></autocomplete>
					</div>
					<div>
						<numberfield
							label="Menge"
							value="current.menge"
							(_change)="updateGesamtPreis()"
							[wave]="true"
						></numberfield> {{ artikeleinheit }}
						<checkbox
							label="Hat mehrere Waagscheine"
							value="current.hatmehrerewaagscheine"
							style="margin-left: 3em; "
						></checkbox>
					</div>
					<div>
						<eurofield
							label="Preis / Einheit"
							value="current.preisproeinheitineur"
							(_change)="updateGesamtPreis()"
						></eurofield>
						<eurofield
							label="Sondermaut"
							value="current.sondermautineur"
							(_change)="updateGesamtPreis()"
						></eurofield>
						<eurofield
							label="Preis (Gesamt)"
							value="current.preisgesamtineur"
						></eurofield>
						<button
							type="button"
							mat-mini-fab
							color="primary"
							style="transform: scale(0.83); "
							(click)="updatePreise($event)"
						><mat-icon>refresh</mat-icon></button>
					</div>
				</mat-card-content>
			</mat-card>
			<hensl-checktag-list
				#checkTagList
				subtable="henslauftragsattribute"
				idfieldname="auftragid"
				filter="fuerauftraege"
			></hensl-checktag-list>
			<form-buttons [delete]="false"></form-buttons>
		</form>
		<mat-card *ngIf="id" style="width: 550px; max-width: 100%; float: left; ">
			<mat-card-title>Letzten {{ getMaxHeutigeAuftraege() }} Aufträge</mat-card-title>
			<mat-card-content>
				<div *ngFor="let auftrag of heutigeAuftraege" style="margin-bottom: 0.4em; ">
					<div style="float: left; width: 100px; ">
						<a routerLink="../{{ auftrag.id }}">
							{{ auftrag.auftragsnummer }}
						</a>
					</div>
					<div style="float: left; width: 240px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; ">
						{{ auftrag.strecke }}
					</div>
					<div style="float: left; width: 110px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; ">
						{{ auftrag.artikel }}
					</div>
					<div style="float: right; width: 100px; text-align: right; ">
						{{ auftrag.preisgesamtineur | number:'1.2-2' }} €
					</div>
					<div style="clear: both; "></div>
				</div>
				<div>
					<a routerLink="../-1" style="margin-top: 1em; ">+ Neuer Auftrag</a>
				</div>
			</mat-card-content>
		</mat-card>
		<mat-card [style.display]="zugehoerigeWaagscheine.length > 0 ? 'block' : 'none'" style="width: 550px; max-width: 100%; float: left; ">
			<mat-card-title>Zugehörige Waagscheine</mat-card-title>
			<mat-card-content>
				<div *ngFor="let waagschein of zugehoerigeWaagscheine" style="margin-bottom: 0.4em; ">
					<a routerLink="/hensl/waagscheine/{{ waagschein.id }}">
						{{ waagschein.waagscheinnummer }}
					</a>
				</div>
			</mat-card-content>
		</mat-card>
	`
}))
export class AuftraegeComponent extends FormComponent<Auftrag>
{
	@ViewChild("autocompleteVonStation")
	autocompleteVonStation: AutoCompleteComponent;
	
	@ViewChild("autocompleteRechnungsempfaenger")
	autocompleteRechnungsempfaenger: AutoCompleteComponent;
	
	@ViewChild("autocompleteFahrzeuge")
	autocompleteFahrzeuge: AutoCompleteComponent;
	
	getTable(): string { return "henslauftraege"; }
	
	stationen: Station[];
	fraechter: Fraechter[];
	fahrzeuge: Fahrzeug[];
	transportarten: Transportart[];
	artikel: Artikel[];
	rechnungsempfaenger: Rechnungsempfaenger[];
	
	heutigeAuftraege: Auftrag[];
	zugehoerigeWaagscheine: Waagschein[];
	
	artikeleinheit: string;
	
	@ViewChild("checkTagList")
	checkTagList: CheckTagListComponent;
	
	constructor(
		private settings: SettingsService,
		injector: Injector
	) {
		super(injector);
		settings.load(() => {
			this.updateHeutigeAuftraege();
		});
	}
	
	onSelectAll(): ExtendedObservable<Auftrag[]> {
		return Auftrag.selectAll(this.data, 200);
	}
	
	onSelectCurrent(): ExtendedObservable<Auftrag> {
		return Auftrag.select(this.data, this.id);
	}
	
	getNew(): Auftrag {
		return new Auftrag(this.data);
	}
	
	onInit(): void {
		this.goToNewAfterEdit = true;
		this.updateZugehoerigeWaagscheine();
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		this.data.loader++;
		forkJoin(
			this.data.query(() => new Station(), `
				select s1.*, s2.stationsnummer as _produzentheimstationsnummer
				from henslstationen as s1
				left join henslstationen as s2 on s2.id = s1.produzentheimstationid
			`),
			this.data.selectAll(() => new Fraechter(), "henslfraechterliste"),
			this.data.selectAll(() => new Transportart(), "hensltransportarten"),
			this.data.selectAll(() => new Artikel(), "henslartikelliste"),
			this.data.selectAll(() => new Rechnungsempfaenger(), "henslrechnungsempfaenger")
		).subscribe(results => {
			this.stationen = results[0];
			this.fraechter = results[1];
			this.transportarten = results[2];
			this.artikel = results[3];
			this.rechnungsempfaenger = results[4];
			if (this.isNew) {
				Auftrag.neueAuftragsnummer(this.data).convert(x => x.nummer).subscribe(nummer => {
					this.current.auftragsnummer = Utils.getYear() + "-" + nummer;
				});
			} else {
				// this.autocompleteVonStation.updateFilteredOptions([], "");
				// this.autocompleteVonStation.selectText();
			}
			this.checkTagList.onLoadCurrent();
			this.updateZugehoerigeWaagscheine();
			this.updateFahrzeuge(false);
			this.updateArtikelEinheit();
			this.data.loader--;
		});
	}
	
	beforeSave(callback: () => void): void {
		if (this.isNew) {
			Auftrag.neueAuftragsnummer(this.data).subscribe(result => {
				this.current.saveNummernkreis(result).subscribe(() => {
					super.beforeSave(callback);
				})
			});
		} else {
			super.beforeSave(callback);
		}
	}
	
	afterSave(callback: () => void): void {
		this.checkTagList.onSave();
		this.updateHeutigeAuftraege();
		super.afterSave(callback);
	}
	
	getInternalHostname() {
		return Utils.getInternalHostname();
	}
	
	updateHeutigeAuftraege() {
		Auftrag.selectAll(this.data, this.getMaxHeutigeAuftraege()).subscribe(result => {
			this.heutigeAuftraege = result;
		});
	}
	
	updateFahrzeuge(updateFilter) {
		this.current._fahrzeuge.subscribe(result => {
			this.fahrzeuge = result;
			if (updateFilter) {
				this.autocompleteFahrzeuge.updateFilteredOptions(result, "");
			}
		});
	}
	
	updateRechnungsadresse() {
		const id = this.current.rechnungsempfaengerid;
		if (!id) {
			return;
		}
		for (const x of this.rechnungsempfaenger) {
			if (x.id === id) {
				this.current.rechnungsadresse = x.adresse;
				return;
			}
		}
	}
	
	updateArtikelEinheit() {
		this.current._artikel.subscribe(artikel => {
			this.artikeleinheit = artikel ? artikel.einheit : "";
		});
	}
	
	updatePreise(e) {
		if (
			isNumber(this.current.vonstationid) &&
			isNumber(this.current.nachstationid) &&
			isNumber(this.current.artikelid)
		) {
			this.current._updatePreise.subscribe(() => {
				this.updateGesamtPreis();
			});
		}
	}
	
	updateGesamtPreis() {
		this.current.preisgesamtineur =
			this.current.preisproeinheitineur
			* this.current.menge
			+ this.current.sondermautineur;
	}
	
	updateZugehoerigeWaagscheine() {
		this.zugehoerigeWaagscheine = [];
		if (this.isCurrent) {
			this.current._waagscheine.subscribe(waagscheine => {
				this.zugehoerigeWaagscheine = waagscheine;
			})
		}
	}
	
	getMaxHeutigeAuftraege() {
		return this.settings.getValue('view.maxHeutigeAuftraege');
	}
	
	getDefaultNachStation() {
		return this.settings.getValue('form.defaultNachStation');
	}
	
	getDefaultTransportart() {
		return this.settings.getValue('form.defaultTransportart');
	}
}
