import { IUnique } from "lib/interfaces/i.unique";

export class BatschImport implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	_temp: any = null;
	
	waagscheinid: number = 0;
	dateiname: string = "";
	index: number = 0;
	abrechnungswerk: string = "";
	vonstation: string = "";
	anstation: string = "";
	lieferdatum: string = "";
	belegnummer: string = "";
	produzent: string = "";
	kennzeichen: string = "";
	brutto: string = "";
	tara: string = "";
	netto: string = "";
	namedervonstation: string = "";
	namederanstation: string = "";
	
	nachname: string = "";
	vorname: string = "";
	abrechnungsnummer: string = "";
	
	toString() {
		return this.vonstation + " - " + this.anstation + ", " + this.lieferdatum + ", "
			+ this.kennzeichen + ", " + this.produzent + ", " + this.netto;
	}
	
	fromCSV(line: string[]) {
		this.abrechnungswerk = line[0];
		this.vonstation = line[1];
		this.anstation = line[2];
		this.lieferdatum = line[3];
		this.belegnummer = line[4];
		this.produzent = line[5];
		this.kennzeichen = line[6];
		this.brutto = line[7];
		this.tara = line[8];
		this.netto = line[9];
		this.namedervonstation = line[10];
		this.namederanstation = line[11];
	}
}
