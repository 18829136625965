import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { cast, castArray, interval } from "lib/utils/functions";
import { ServerTime } from "lib/classes/server-time";
import { Observable, forkJoin, of } from "rxjs";
import { ExtendedObservable } from "lib/classes/extended-observable";
import { Utils } from "lib/utils/utils";

@Injectable()
export abstract class DatabaseService
{
	abstract getAPIPath(): string;
	
	protected timeOffset: number = 0;
	
	protected defaultOptions = {
		headers: new HttpHeaders().append(
			'Content-Type', 'application/json'
		)
	};
	
	
	constructor(
		protected http: HttpClient
	) {
		
	}
	
	public execute(...sql: string[]): ExtendedObservable<any> {
		let queries: Observable<any>[] = [];
		for (let s of sql) {
			queries.push(
				this.http.post<any>(
					this.getAPIPath() + "/query", { sql: s }, this.defaultOptions
				)
			);
		}
		return ExtendedObservable.from(forkJoin(...queries));
	}
	
	public query<T>(newT: () => T, sql: string): ExtendedObservable<T[]> {
		let result = ExtendedObservable.from(
			this.http.post<T[]>(
				this.getAPIPath() + "/query",
				{ sql: sql },
				this.defaultOptions
			)
		);
		if (newT) {
			result = result.convert(
				newResult => castArray(newResult, () => newT())
			);
		}
		return result;
	}
	
	public selectAll<T>(newT: () => T, table: string, suffix?: string): ExtendedObservable<T[]> {
		return this.query<T>(newT, "select * from " + table + " " + (suffix ? suffix : ""));
	}
	
	public single<T>(newT: () => T, sql: string): ExtendedObservable<T> {
		let result = ExtendedObservable.from(
			this.http.post<T>(
				this.getAPIPath() + "/single",
				{ sql: sql },
				this.defaultOptions
			)
		);
		if (newT) {
			result = result.convert(
				newResult => cast(newResult, () => newT())
			);
		}
		return result;
	}
	
	public get(url: string): ExtendedObservable<any> {
		return ExtendedObservable.from(this.http.get<any>(url));
	}
	
	public read(url: string): ExtendedObservable<string[]> {
		return ExtendedObservable.from(
			this.http.get(url, { responseType: 'text' })
		).convert(content => {
			let result = content.split('\n');
			if (result[result.length - 1] == '') {
				result.splice(result.length - 1, 1);
			}
			return result;
		});
	}
	
	public getServerTime(): ExtendedObservable<ServerTime> {
		return this.get("/api/time?offset=" + this.timeOffset).convert(
			date => cast(date, () => new ServerTime())
		);
	}
	
	getDate(unixtime: number): Date {
		return new Date(unixtime * 1000);
	}
	
	getTimeString(unixtime: number): string {
		let h = '' + Math.floor(unixtime / 3600);
		let m = '' + Math.floor(unixtime % 3600 / 60);
		let s = '' + unixtime % 60;
		if (h.length == 1) h = '0' + h;
		if (m.length == 1) m = '0' + m;
		if (s.length == 1) s = '0' + s;
		return [h, m, s].join(':');
	}
	
	jsonp(url: string, params: any): ExtendedObservable<boolean> {
		return ExtendedObservable.from(
			this.http.jsonp<boolean>(Utils.createURL(url, params), 'callback')
		);
	}
	
	mail(from: string, to: string, title: string, text: string): ExtendedObservable<boolean> {
		return this.jsonp("https://gantt.chvaco.at/api/index.php/v3/wog/mail/", {
			from: from, to: to, title: title, text: text
		});
	}
}
