import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Station } from 'app/hensl/classes/station';
import { Stationsfeld } from 'app/hensl/classes/stationsfeld';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { Land } from 'app/hensl/classes/land';
import { merge } from 'lib/utils/functions';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Utils } from 'lib/utils/utils';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-stationen',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Stationen</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'stationsnummer', label: 'Nummer' },
					{ name: 'stationsbezeichnung', label: 'Bezeichnung' },
					{ name: 'adresse', label: 'Adresse' },
					{ name: 'plz', label: 'PLZ' },
					{ name: 'ort', label: 'Ort' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neue Station</button>
		</div>
		<form *ngIf="id">
			<mat-card style="float: left; margin-right: 2em; ">
				<div>
					<textfield _focus
						label="Stations- / Produzentennummer"
						value="current.stationsnummer"
						[wave]="true"
					></textfield>
					<autocomplete
						label="Heimstation von Produzent" value="current.produzentheimstationid"
						[from]="stationen" [wave]="true"
					></autocomplete>
				</div>
				<div>
					<textfield
						label="Bezeichnung"
						value="current.stationsbezeichnung"
						[wave]="true"
					></textfield>
					<numberfield
						label="Distanz"
						value="current.distanzinkilometer"
						[wave]="true"
						[enabled]="false"
					></numberfield>
				</div>
				<div>
					<textfield
						label="Adresse"
						value="current.adresse"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Postleitzahl"
						value="current.plz"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Ort"
						value="current.ort"
						[wave]="true"
					></textfield>
				</div>
				
				<div>
					<autocomplete
						label="Land" value="current.landid"
						[from]="laender" [wave]="true"
					></autocomplete>
				</div>
				
			</mat-card>
			
			<mat-card style="float: left; " *ngIf="current.id">
				<h3>Felder</h3>
				<div *ngFor="let stationsfeld of vorhandeneStationsfelder" style="display: flex; gap: .5em; ">
					<mat-form-field><input
						matInput
						type="text"
						placeholder="Nummer"
						[value]="stationsfeld.feldnummer"
						(change)="stationsfeld.feldnummer = $event.target.value"
					></mat-form-field>
					<mat-form-field><input
						matInput
						type="text"
						placeholder="Name"
						[value]="stationsfeld.feldname"
						(change)="stationsfeld.feldname = $event.target.value"
					></mat-form-field>
					<mat-form-field><input
						matInput
						type="number"
						placeholder="Distanz"
						[value]="stationsfeld.distanzinkilometer"
						(change)="stationsfeld.distanzinkilometer = $event.target.value"
					></mat-form-field>
					<button
						type="button"
						mat-mini-fab
						color="warn"
						style="transform: scale(0.83); margin-top: .5em; "
						(click)="deleteStationsfeld(stationsfeld)"
					><mat-icon>delete_forever</mat-icon></button>
				</div>
				<div>
					<button
						type="button"
						mat-mini-fab
						color="primary"
						(click)="addStationsfeld()"
					><mat-icon>add</mat-icon></button>
				</div>
			</mat-card>
			
			<div style="clear: both; "></div>
			
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
		<!--hensl-tarife></hensl-tarife-->
	`
}))
export class StationenComponent extends FormComponent<Station>
{
	stationen: Station[];
	stationsfelder: Stationsfeld[] = [];
	laender: Land[];
	
	get vorhandeneStationsfelder() {
		return this.stationsfelder.filter(x => !x._deleted);
	}
	
	getTable(): string { return "henslstationen"; }
	
	getNew(): Station {
		return new Station();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		this.loadStationsfelder();
		this.data.loader += 2;
		this.data.selectAll(() => new Station(), "henslstationen").subscribe(stationen => {
			this.stationen = stationen;
			this.data.loader--;
		});
		this.data.selectAll(() => new Land(), "hensllaender").subscribe(laender => {
			this.laender = laender;
			this.data.loader--;
		});
	}
	
	afterSave(callback: () => void): void {
		this.saveStationsfelder(() => {
			let vonstationid = this.current.id;
			super.afterSave(callback);
			if (this.isNew) {
				Utils.goToURL(this.router, this.route, "/hensl/tarife/-1?vonstationid=" + vonstationid);
			}
		});
	}
	
	saveStationsfelder(callback: () => void): void {
		let queries: ExtendedObservable<any>[] = [];
		for (const stationsfeld of this.stationsfelder) {
			if (stationsfeld._deleted) {
				if (stationsfeld.id > 0) {
					queries.add(this.data.delete("/api/dynamic/henslstationenfelder", { id: stationsfeld.id }));
				}
			} else {
				queries.add(this.data.set("/api/dynamic/henslstationenfelder", stationsfeld));
			}
		}
		if (queries.length == 0) {
			queries.add(ExtendedObservable.empty);
		}
		forkJoin(...queries).subscribe(() => {
			callback();
		});
	}
	
	loadStationsfelder() {
		this.data.loader++;
		this.data.query(() => new Stationsfeld(),
			"select * from henslstationenfelder where stationid = " + this.current.id
		).subscribe(stationsfelder => {
			this.stationsfelder = stationsfelder;
			this.data.loader--;
		});
	}
	
	addStationsfeld() {
		let stationsfeld = new Stationsfeld();
		stationsfeld.stationid = this.current.id;
		this.stationsfelder.add(stationsfeld);
	}
	
	deleteStationsfeld(stationsfeld: Stationsfeld) {
		stationsfeld._deleted = true;
	}
}
