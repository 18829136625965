import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewContainerRef,
	Injectable,
	ViewChild,
	Renderer
} from "@angular/core";

import {
	FormControl, FormGroup
} from "@angular/forms";
import { Utils } from "lib/utils/utils";
import { invoke, isDefined } from "lib/utils/functions";
import { FormComponent } from "lib/components/form.component";
import { DelayedFunction } from "lib/classes/delayed-function";

@Injectable()
export abstract class InputComponent implements OnInit
{
	@Input('label') INPUT_label: string = null;
	@Input('value') INPUT_valueName: string = null;
	@Input('enabled') INPUT_enabled: boolean = true;	
	@Input("wave") INPUT_wave: boolean = false;
	@Input('defaultValue') INPUT_defaultValue: any;
	@Input('width') INPUT_width: string;
	@Input('_focus') INPUT_focus;
	
	@Output('_change') OUTPUT_change = new EventEmitter<any>();
	
	@ViewChild("input") protected _input: any;
	get input() {
		return this._input.nativeElement;
	}
	
	control: FormControl = new FormControl();

	private _changeEventEnabled: boolean = true;
	
	constructor(
		protected container: ViewContainerRef,
		protected renderer: Renderer
	) {
		
	}
	
	performChangeEvent(value) {
		if (this._changeEventEnabled) {
			this.OUTPUT_change.emit(value);
		}
	}
	
	get self(): Component {
		return Utils.getSelf(this.container) as Component;
	}
	
	get parent(): FormComponent<any> {
		return Utils.getParent(this.container) as FormComponent<any>;
	}
	
	get form(): FormGroup {
		return this.parent["form"];
	}
	
	ngOnInit(): void {
		if (this.parent.loadCurrentFunctions) {
			this.parent.loadCurrentFunctions.push(
				new DelayedFunction(() => this.onLoad(), 0)
			);
		}
		if (this.parent.loadCurrentDelayedFunctions) {
			this.parent.loadCurrentDelayedFunctions.push(
				new DelayedFunction(() => this.onLoadDelayed(), 220)
			);
		}
		if (this.INPUT_valueName) {
			if (this.INPUT_label === null) {
				this.INPUT_label = this.INPUT_valueName;
			}
			if (this.form) {
				this.form.addControl(this.INPUT_valueName, this.control);
			}
		}
		// this.performChangeEvent(this.getValue());
	}
	
	setDefaultValue() {
		if (isDefined(this.INPUT_defaultValue)) {
			if (!this.getValue()) {
				this._changeEventEnabled = false;
				this.setValue(this.INPUT_defaultValue);
				this._changeEventEnabled = true;
			}
		}
	}
	
	onLoad() {
		this.setDefaultValue();
	}
	
	onLoadDelayed() {
		// this.setDefaultValue();
		if (this.INPUT_focus !== undefined) {
			this.focus();
		}
	}
	
	focus() {
		invoke(this.renderer, this._input, "focus");
	}
	
	selectText(e?) {
		if (e) {
			e.preventDefault();
			e.target.select();
		} else {
			invoke(this.renderer, this._input, "select");
		}
	}
	
	getValue(): any {
		if (this.INPUT_valueName == null) {
			return null;
		}
		let tree = this.INPUT_valueName.split('.');
		let value: any;
		if (isDefined(this.self[tree[0]])) {
			value = this.self;
		} else {
			value = this.parent;
		}
		while (tree.length > 0) {
			value = value[tree[0]];
			tree.shift();
		}
		return value;
	}
	
	setValue(newValue: any): void {
		if (this.INPUT_valueName == null) {
			return;
		}
		let tree = this.INPUT_valueName.split('.');
		let value: any;
		if (isDefined(this.self[tree[0]])) {
			value = this.self;
		} else {
			value = this.parent;
		}
		while (tree.length > 1) {
			value = value[tree[0]]
			tree.shift();
		}
		value[tree[0]] = newValue;
		this.performChangeEvent(newValue);
		this.updateValidity();
	}
	
	updateValidity() {
		if (this.form) {
			this.form.updateValueAndValidity();
		}
	}
	
	tabTo(e, offset) {
		Utils.tabTo(this.input, e, offset);
	}
	
	tabToPrevious(e) {
		Utils.tabToPrevious(this.input, e);
	}
	
	tabToNext(e) {
		Utils.tabToNext(this.input, e);
	}
}
