import { Component, Input } from "@angular/core";
import { InputComponent } from "lib/components/input.component";

@Component({
	selector: 'textfield',
	styles: [`
		mat-form-field {
			margin: 0 1em 0 0;
			max-width: 100%;
		}
	`],
	// appearance="outline"
	template: `
		<mat-form-field
			[class.wave-input-field]="INPUT_wave"
			[class.no-underline]="INPUT_wave"
			[style.width]="INPUT_width ? INPUT_width : '300px'"
		>
			<input
				matInput
				#input
				[type]="INPUT_type"
				[formControl]="control"
				[placeholder]="INPUT_label"
				[_disabled]="!INPUT_enabled"
				[value]="getValue()"
				(focusin)="selectText($event)"
				(input)="setValue($event.target.value)"
				(keydown.enter)="tabToNext($event)"
				(keydown.ArrowLeft)="onKeyDownArrowLeft($event)"
				(keydown.ArrowRight)="onKeyDownArrowRight($event)"
				autocomplete="off"
			>
			<div *ngIf="INPUT_wave" class="wave-input-svg-container">
				<svg preserveAspectRatio="none" viewBox="0 0 1200 60" width="300%" height="100%" class="wave-input-svg">
					<path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
				</svg>
			</div>
		</mat-form-field>
	`
})
export class TextFieldComponent extends InputComponent
{
	@Input("type") INPUT_type: string = "text";
	
	onKeyDownArrowLeft(e)
	{
		this.tabToPrevious(e);
	}
	
	onKeyDownArrowRight(e)
	{
		this.tabToNext(e);
	}
}