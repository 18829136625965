import { IUnique } from "lib/interfaces/i.unique";
import { Fahrzeug } from "app/hensl/classes/fahrzeug";
import { Utils } from "lib/utils/utils";
import { Artikel } from "app/hensl/classes/artikel";
import { Fraechter } from "app/hensl/classes/fraechter";
import { DataService } from "lib/services/data.service";
import { ExtendedObservable } from "lib/classes/extended-observable";
import { Transportart } from "app/hensl/classes/transportart";
import { Tarifpreis } from "app/hensl/classes/tarifpreis";

export class Auftrag implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	auftragsnummer: string = "";
	vonstationid: number = 0;
	nachstationid: number = 0;
	fahrerid: number = 0;
	fahrzeugid: number = 0;
	anlagedatum: Date = new Date();
	aenderungsdatum: Date = new Date();
	planmaessigesstartdatum: Date = new Date();
	planmaessigesenddatum: Date = new Date();
	planmaessigeladezeit: string = "00:00";
	planmaessigeentladezeit: string = "00:00";
	mautineur: number = 0.00;
	transportartid: number = 0;
	artikelid: number = 0;
	menge: number = 0.00;
	preisproeinheitineur: number = 0.00;
	sondermautineur: number = 0.00;
	preisgesamtineur: number = 0.00;
	erledigt: boolean = false;
	rechnungsempfaengerid: number = 0;
	rechnungsadresse: string = "";
	hatmehrerewaagscheine: boolean = false;
	
	_fraechterid: number = 0;
	
	constructor(private _data: DataService) {
		
	}
	
	private _toString: string;
	toString(): string {
		if (this._toString === undefined) {
			this._toString = this.auftragsnummer.toString() + " | ...";
			this._data.single<any>(null, `
				select top 10 a.*,
					fr.fraechtername,
					fz.kennzeichen,
					art.artikelnummer,
					art.artikelbezeichnung,
					concat(s1.stationsnummer, ' ', s1.stationsbezeichnung) as von,
					concat(s2.stationsnummer, ' ', s2.stationsbezeichnung) as nach,
					aatr1.wert as aatr1,
					aatr2.wert as aatr2,
					aatr3.wert as aatr3
				from henslauftraege as a
				left join henslstationen as s1 on s1.id = a.vonstationid
				left join henslstationen as s2 on s2.id = a.nachstationid
				left join henslfahrzeuge as f on f.id = a.fahrzeugid
				left join henslfraechterliste as fr on fr.id = f.fraechterid
				left join henslfahrzeuge as fz on fz.id = a.fahrzeugid
				left join henslartikelliste as art on art.id = a.artikelid
				left join henslauftragsattribute as aatr1 on aatr1.auftragid = a.id and aatr1.attributid = 11
				left join henslauftragsattribute as aatr2 on aatr2.auftragid = a.id and aatr2.attributid = 12
				left join henslauftragsattribute as aatr3 on aatr3.auftragid = a.id and aatr3.attributid = 19
				where a.id = ` + this.id
			).convert(temp => {
				let startDate = temp.planmaessigesstartdatum as Date;
				let start = startDate ? startDate.toGermanDateString() : "???";
				let endDate = temp.planmaessigesenddatum as Date;
				let end = endDate ? endDate.toGermanDateString() : "???";
				return temp.auftragsnummer
					+ "   " + start + " - " + end
					+ "   " + temp.von + " ► " + temp.nach
					// + (temp.fraechtername ? "   " + temp.fraechtername : "")
					+ (temp.aatr1   ? "   A-Nr.: " + temp.aatr1   : "")
					+ (temp.aatr2   ? "   B-Nr.: " + temp.aatr2   : "")
					+ (temp.aatr3   ? "   L-Nr.: " + temp.aatr3   : "")
					// + (temp.kennzeichen   ? "   " + temp.kennzeichen   : "")
					// + (temp.artikelnummer ? "   " + temp.artikelnummer + " " + temp.artikelbezeichnung : "");
			}).subscribe(result => {
				this._toString = result;
			});
		}
		return this._toString;
	}
	
	static selectAll(data: DataService, max: number): ExtendedObservable<any[]> {
		return data.query<any>(null, `
			select ` + (max > 0 ? "top " + max : "") + `
				a.*,
				concat(s1.stationsnummer, ' ', s1.stationsbezeichnung) as von,
				concat(s2.stationsnummer, ' ', s2.stationsbezeichnung) as nach,
				fz.kennzeichen as fahrzeug,
				concat(art.artikelnummer, ' ', art. artikelbezeichnung) as artikel
			from henslauftraege as a
			left join henslstationen as s1 on s1.id = a.vonstationid
			left join henslstationen as s2 on s2.id = a.nachstationid
			left join henslfahrzeuge as fz on fz.id = a.fahrzeugid
			left join henslartikelliste as art on art.id = a.artikelid
			` + (max > 0 ? "order by id desc" : "") + `
		`).convert(values => {
			for (let value of values) {
				value.strecke = value.von + " ► " + value.nach;
			}
			return values;
		});
	}
	
	static select(data: DataService, id: number): ExtendedObservable<Auftrag> {
		return data.single(() => new Auftrag(data), `
			select top 1 a.*, fr.id as _fraechterid
			from henslauftraege as a
			left join henslfahrzeuge as f on f.id = a.fahrzeugid
			left join henslfraechterliste as fr on fr.id = f.fraechterid
			where a.id = ` + id
		);
	}
	
	static neueAuftragsnummer(data: DataService): ExtendedObservable<any> {
		return data.single<any>(null, `
			select id, (nummer + 1) as nummer
			from henslnummernkreise
			where gruppe = 'Auftrag'
			and jahr = ` + Utils.getYear()
		);
	}
	
 	get _fahrzeuge(): ExtendedObservable<Fahrzeug[]> {
		return this._data.query(() => new Fahrzeug(), "select henslfahrzeuge.* from henslfraechterliste "
			+ "join henslfahrzeuge on henslfahrzeuge.fraechterid <> 0 and henslfahrzeuge.fraechterid = henslfraechterliste.id "
			+ "where henslfraechterliste.id = " + this._fraechterid);
	}
	 
	get _preis(): ExtendedObservable<Tarifpreis> {
		return this._data.single(() => new Tarifpreis(), "select top 1 hensltarifpreise.* from hensltarifpreise "
			+ "join hensltarife on hensltarife.id = hensltarifpreise.tarifid "
			+ "where vonstationid = " + this.vonstationid + " "
			+ "and nachstationid = " + this.nachstationid + " "
			+ "and artikelid = " + this.artikelid + " "
			+ "and transportartid = " + this.transportartid + " "
		);
	}
	
	saveNummernkreis(nummernkreis: any): ExtendedObservable<void> {
		let neueNummer = nummernkreis.nummer;
		return this._data.set("/api/dynamic/henslnummernkreise", {
			id: nummernkreis.id,
			nummer: neueNummer
		}).convert(result => {
			this.auftragsnummer = Utils.getYear() + "-" + neueNummer;
			return result;
		});
	}
	
	get _waagscheine(): ExtendedObservable<any[]> {
		return this._data.query<any>(null,
			"select id, waagscheinnummer from henslwaagscheine where auftragid = " + this.id
		);
	}
	
	get _transportart(): ExtendedObservable<Transportart> {
		if (this.transportartid > 0) {
			return this._data.single(() => new Transportart(),
				"select * from hensltransportarten where id = " + this.transportartid
			);
		}
		return ExtendedObservable.of<Transportart>();
	}
	
	get _artikel(): ExtendedObservable<Artikel> {
		if (this.artikelid > 0) {
			return this._data.single(() => new Artikel(),
				"select * from henslartikelliste where id = " + this.artikelid
			);
		}
		return ExtendedObservable.of<Artikel>();
	}
	
	get _fraechter(): ExtendedObservable<Fraechter> {
		return this._data.selectAll(
			() => new Fraechter(), "henslfraechterliste"
		).convert(
			fraechterliste => {
				for (let fraechter of fraechterliste) {
					if (fraechter.id == this._fraechterid) {
						return fraechter;
					}
				}
				return null as Fraechter;
			}
		);
	}
	
	get _updatePreise(): ExtendedObservable<void> {
		this.preisproeinheitineur = 0;
		this.sondermautineur = 0;
		return this._transportart.exchange(
			transport => {
				this.preisproeinheitineur += transport.zuschlagproeinheitineur;
				return this._preis;
			}
		).exchange(
			preis => {
				if (preis != null) {
					this.sondermautineur += +preis.sondermautineur;
					return this._fraechter.exchange(fraechter => {
						if (fraechter && fraechter.hatfraechterpreis) {
							this.preisproeinheitineur += +preis.fraechterpreisineur;
						} else {
							this.preisproeinheitineur += +preis.grundpreisineur;
						}
						return ExtendedObservable.of(null);
					});
				}
				return ExtendedObservable.of(null);
			}
		);
	}
	
	get _attribute() {
		return this._data.query<any>(null, `select
			henslauftragsattribute.attributid as id,
			henslauftragsattribute.wert,
			henslattribute.isteindeutig
			from henslauftragsattribute 
			join henslattribute on henslattribute.id = henslauftragsattribute.attributid
			where henslauftragsattribute.auftragid = ` + this.id);
	}
}
