import { IUnique } from "lib/interfaces/i.unique";

export class Land implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	kuerzel: string = "";
	bezeichnung: string = "";
	
	toString() {
		return this.kuerzel + " " + this.bezeichnung;
	}
}
