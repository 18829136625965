import { Component, ViewChild } from '@angular/core';
import { getRouterTransition } from 'app/hensl/animations';
import { SettingsService } from "app/hensl/services/settings.service";
import { DataService } from 'lib/services/data.service';

@Component({
	selector: 'hensl-root',
	styleUrls: [`./root.component.scss`],
	template: `
		<mat-drawer-container class="container" [hasBackdrop]="!menuStaysOpen" >
			<mat-drawer #drawer
				class="sidenav"
				[mode]="(menuStaysOpen ? 'side' : 'over')"
				[opened]="menuStaysOpen"
				[class.shadow]="menuStaysOpen"
			>
				<mat-nav-list class="navlist">
					<a
						mat-list-item routerLink="/hensl"
						(click)="closeDrawer()"
						class="listitem toplistitem"
					>Übersicht</a>
					<a
						mat-list-item
						(click)="closeDrawer()"
						class="listitem"
						routerLink="hensl/{{ nav[0] }}"
						*ngFor="let nav of navigation"
					>{{ nav[1] }}</a>
					<a
						mat-list-item href="/"
						(click)="closeDrawer()"
						class="listitem bottomlistitem"
					>Beenden</a>
				</mat-nav-list>
			</mat-drawer>
			<mat-drawer-content class="content"
				[@routerTransition0px]="(menuStaysOpen ? undefined : routeState)"
				[@routerTransition280px]="(menuStaysOpen ? routeState : undefined)"
			>
				<mat-toolbar color="primary" class="toolbar" style="background: #575 !important; ">
					<button *ngIf="!menuStaysOpen" mat-icon-button (click)="toggleDrawer()" style="margin: 0.15em 0 0 !important; ">
						<mat-icon>menu</mat-icon>
					</button>
					<h1>HENSL Transport GesmbH</h1>
					<div *ngIf="settings.getValue('debug.showLoadingIntensity')" class="loading-intensity">
						<div style="font-size: 0.55em; opacity: 0.65; ">loader</div>
						<div style="font-weight: normal; ">{{ loader }}</div>
					</div>
					<div *ngIf="settings.getValue('debug.showAnimationState')" class="animation-state">
						<div style="font-size: 0.55em; opacity: 0.65; ">animation state</div>
						<div style="font-weight: normal; ">{{ routeState }}</div>
					</div>
				</mat-toolbar>
				<loader></loader>
				<div class="outlet">
					<router-outlet #rootOutlet="outlet"></router-outlet>
					<div style="width: 100%; height: 2em; "></div>
				</div>
			</mat-drawer-content>
		</mat-drawer-container>
	`,
	animations: [
		getRouterTransition("0px"),
		getRouterTransition("280px")
	],
	providers: [
		SettingsService
	]
})
export class RootComponent
{
	navigation = [
		["artikel", "Artikel"],
		["attribute", "Attribute"],
		["auftraege", "Aufträge"],
		["auswertung", "Auswertung"],
		["importe", "Importe"],
		["einstellungen", "Einstellungen"],
		["fahrzeugtypen", "Fahrzeugtypen"],
		["fraechter", "Frächter"],
		["rechnungen", "Rechnungen"],
		["rechnungsempfaenger", "Rechnungsempfänger"],
		["stationen", "Stationen"],
		["tarife", "Tarife"],
		["transportarten", "Transportarten"],
		["waagscheine", "Waagscheine"]
	];
	
	@ViewChild("rootOutlet")
	rootOutlet: any;
	
	@ViewChild("drawer")
	drawer: any;
	
	get routeState() {
		return this.rootOutlet.activatedRouteData.state;
	}
	
	constructor(private settings: SettingsService, private data: DataService) {
		settings.load();
	}
	
	get loader() {
		return this.data.loader;
	}
	
	get menuStaysOpen(): boolean {
		return this.settings.getValue('view.menuStaysOpen') as boolean;
	}
	
	closeDrawer() {
		if (!this.menuStaysOpen) {
			this.drawer.close();
		}
	}
	
	toggleDrawer() {
		if (!this.menuStaysOpen) {
			this.drawer.toggle();
		}
	}
}
