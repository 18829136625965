import { Component } from '@angular/core';
import { DataService } from 'lib/services/data.service';

@Component({
	selector: 'loader',
	template: `
		<div [class.loader-hidden]="!visible">
			<div class="loader-overlay">
				<div>
					<mat-progress-bar
						mode="indeterminate"
						color="warn">
					</mat-progress-bar>
				</div>
			</div>
		</div>
	`,
	styles: [`
		.loader-overlay {
			position: absolute;
			width: 100%;
			top: 64px;
			height: 3px;
			overflow: hidden;
			left: 0;
			opacity: 1;
			z-index: 500000;
			transition: opacity 200ms;
		}
		.loader-hidden .loader-overlay {
			opacity: 0;
		}
	`]
})
export class LoaderComponent
{
	constructor(private data: DataService) {
		
	}
	
	get visible() {
		return this.data.loading;
	}
}
