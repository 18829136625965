import {
	Component,
	OnInit
} from '@angular/core';

import { DataService } from "lib/services/data.service";
import { Subscription } from 'rxjs';

@Component({
	selector: 'hensl-home',
	styles: [`
		
	`],
	template: `
		<h2>Übersicht</h2>
		<mat-form-field style="width: 100%; margin-top: 2em; font-size: 1.5em; ">
			<input _focus
				matInput
				placeholder="Globale Suche"
				autocomplete="off"
				(keydown.enter)="suchen()"
				(input)="setSearchText($event.target.value)"
			>
		</mat-form-field>
		<div class="block" style="position: absolute; right: 0; top: 0; ">
			<img src="assets/hensl-logo.png" style="opacity: 0.4; ">
		</div>
		<div style="clear: both; "></div>
		<div class="suchergebnisse">
			<div *ngFor="let ergebnis of suchergebnisse">
				<span style="font-weight: bold; color: #999; ">
					{{ ergebnis.type }}
				</span>
				<a [routerLink]="ergebnis.link">
					{{ ergebnis.label }}
				</a>
				<span style="">
					{{ ergebnis.text.join('&nbsp;&nbsp;&nbsp;') }}
				</span>
			</div>
		</div>
	`
})
export class HomeComponent implements OnInit
{
	searchString: string;
	suchergebnisse: any[];
	sub: Subscription = null;
	
	constructor(private data: DataService) {
		
	}
	
	ngOnInit(): void {
		
	}
	
	setSearchText(searchString: string) {
		this.searchString = searchString;
	}
	
	suchen() {
		const searchString = this.searchString;
		if (searchString == '') {
			this.suchergebnisse = [];
			return;
		}
		let auftraegeSQL = `
			select top 50
				a.*,
				s1.stationsbezeichnung as von,
				s2.stationsbezeichnung as nach,
				fz.kennzeichen as fahrzeug,
				concat(art.artikelnummer, ' ', art.artikelbezeichnung) as artikel,
				concat('A-Nr.: ', aatr1.wert) as aatr1,
				concat('B-Nr.: ', aatr2.wert) as aatr2
			from henslauftraege as a
			left join henslstationen as s1 on s1.id = a.vonstationid
			left join henslstationen as s2 on s2.id = a.nachstationid
			left join henslfahrzeuge as fz on fz.id = a.fahrzeugid
			left join henslartikelliste as art on art.id = a.artikelid
			left join henslauftragsattribute as att on att.auftragid = a.id
			left join henslauftragsattribute as aatr1 on aatr1.auftragid = a.id and aatr1.attributid = 11
			left join henslauftragsattribute as aatr2 on aatr2.auftragid = a.id and aatr2.attributid = 12
			where (
				a.auftragsnummer like '%` + searchString + `%' or
				s1.stationsbezeichnung like '%` + searchString + `%' or
				s2.stationsbezeichnung like '%` + searchString + `%' or
				fz.kennzeichen like '%` + searchString + `%' or
				art.artikelbezeichnung like '%` + searchString + `%' or
				art.artikelnummer like '%` + searchString + `%' or
				att.wert like '` + searchString + `'
			)
		`;
		
		let waagscheineSQL = `
			select top 50
				a.*,
				concat(s1.stationsnummer, ' ', s1.stationsbezeichnung) as von,
				concat(s2.stationsnummer, ' ', s2.stationsbezeichnung) as nach,
				fz.kennzeichen as fahrzeug,
				concat(art.artikelnummer, ' ', art.artikelbezeichnung) as artikel,
				art.einheit, art.einheit,
				concat('A-Nr.: ', watr1.wert) as watr1,
				concat('W-Nr.: ', watr2.wert) as watr2,
				concat('CMR: ', watr3.wert) as watr3
			from henslwaagscheine as a
			left join henslstationen as s1 on s1.id = a.vonstationid
			left join henslstationen as s2 on s2.id = a.nachstationid
			left join henslfahrzeuge as fz on fz.id = a.fahrzeugid
			left join henslartikelliste as art on art.id = a.artikelid
			left join henslwaagscheinattribute as att on att.waagscheinid = a.id
			left join henslwaagscheinattribute as watr1 on watr1.waagscheinid = a.id and watr1.attributid = 11
			left join henslwaagscheinattribute as watr2 on watr2.waagscheinid = a.id and watr2.attributid = 2
			left join henslwaagscheinattribute as watr3 on watr3.waagscheinid = a.id and watr3.attributid = 15
			where (
				a.waagscheinnummer like '%` + searchString + `%' or
				s1.stationsbezeichnung like '%` + searchString + `%' or
				s2.stationsbezeichnung like '%` + searchString + `%' or
				fz.kennzeichen like '%` + searchString + `%' or
				art.artikelbezeichnung like '%` + searchString + `%' or
				art.artikelnummer like '%` + searchString + `%' or
				att.wert like '` + searchString + `' or
				att.wert like '5016` + searchString.substr(2) + `' or
				att.wert like '5016` + searchString + `'
			)
		`;
		
		let rechnungenSQL = `
			select top 50
				r.*
			from henslrechnungen as r
			where (
				r.rechnungsnummer like '%` + searchString + `%'
			)
		`;
		
		let temp = [];
		
		const sub = this.data.query<any>(null, auftraegeSQL).subscribe(rows => {
			for (let row of rows) {
				temp.push({
					type: "Auftrag",
					label: row.auftragsnummer,
					link: "/hensl/auftraege/" + row.id,
					text: [
						(row.anlagedatum as string).toGermanDateString(),
						row.von + " ► " + row.nach,
						row.fahrzeug,
						row.artikel,
						(Math.round(row.preisgesamtineur * 100) / 100).toFixed(2),
						row.aatr1, row.aatr2
					]
				});
			}
			this.data.query<any>(null, waagscheineSQL).subscribe(rows => {
				for (let row of rows) {
					temp.push({
						type: "Waagschein",
						label: row.waagscheinnummer,
						link: "/hensl/waagscheine/" + row.id,
						text: [
							(row.waagscheindatum as string).toGermanDateString(),
							row.von + " ► " + row.nach,
							row.fahrzeug,
							row.artikel,
							// (Math.round(row.preisgesamtineur * 100) / 100).toFixed(2),
							row.menge + " " + row.einheit,
							row.watr1, row.watr2, row.watr3
						]
					});
				}
				if (this.sub == sub) {
					this.suchergebnisse = temp;
				}
			});
			this.data.query<any>(null, rechnungenSQL).subscribe(rows => {
				for (let row of rows) {
					temp.push({
						type: "Rechnung",
						label: row.rechnungsnummer,
						link: "/hensl/rechnungen/" + row.id,
						text: [
							" vom " + (row.rechnungsdatum as string).toGermanDateString(),
							row.warenempfaenger,
							row.artikeltext
						]
					});
				}
				if (this.sub == sub) {
					this.suchergebnisse = temp;
				}
			});
		});
		this.sub = sub;
	}
}
