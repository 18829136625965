import { IUnique } from "lib/interfaces/i.unique";

export class Attribut implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	name: string = "";
	isteindeutig: boolean = false;
	fuerauftraege: boolean = false;
	fuerwaagscheine: boolean = false;
	fuerrechnungen: boolean = false;
	istproduzent: boolean = false;
	
	toString() {
		return this.name;
	}
}
