import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Fahrzeugtyp } from 'app/hensl/classes/fahrzeugtyp';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { merge } from 'lib/utils/functions';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-fahrzeugtypen',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Fahrzeugtypen</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'name', label: 'Name' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">
				+ Neuer Fahrzeugtyp
			</button>
		</div>
		<form *ngIf="id">
			<mat-card>
				<div>
					<textfield _focus
						label="Name"
						value="current.name"
						[wave]="true"
					></textfield>
				</div>
			</mat-card>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class FahrzeugtypenComponent extends FormComponent<Fahrzeugtyp>
{
	getTable(): string { return "henslfahrzeugtypen"; }
	
	getNew(): Fahrzeugtyp {
		return new Fahrzeugtyp();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		
	}
}
