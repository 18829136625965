import { Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Tarif } from 'app/hensl/classes/tarif';
import { Station } from 'app/hensl/classes/station';
import { Artikel } from 'app/hensl/classes/artikel';
import { forkJoin, Observable } from 'rxjs';
import { Transportart } from 'app/hensl/classes/transportart';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { merge } from 'lib/utils/functions';
import { Tarifpreis } from 'app/hensl/classes/tarifpreis';
import { AutoCompleteComponent } from 'lib/components';
import { Utils } from 'lib/utils/utils';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-tarife',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Tarife</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: '_vonstation', label: 'Von Station' },
					{ name: '_nachstation', label: 'Nach Station' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neuer Tarif</button>
		</div>
		<form *ngIf="id">
			<mat-card>
				<div>
					<autocomplete
						#vonstation
						label="Von Station"
						value="current.vonstationid" [from]="stationen"
						[wave]="true"
					></autocomplete>
					<autocomplete
						label="Nach Station"
						value="current.nachstationid" [from]="stationen"
						[wave]="true"
					></autocomplete>
				</div>
			</mat-card>
			<mat-card>
				<div *ngFor="let preis of vorhandenePreise">
					
					
					
					<mat-form-field style="width: 200px; margin-right: 0.5em; ">
						<input
							matInput
							[placeholder]="Artikel"
							[matAutocomplete]="auto1"
							aria-label="Number"
							[value]="getArtikelText(preis.artikelid)"
							#artikel1
							(keydown.enter)="tabToNext(artikel1, $event)"
							(keydown.ArrowLeft)="tabToPrevious(artikel1, $event)"
							(keydown.ArrowRight)="tabToNext(artikel1, $event)"
						>
						<mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
							<mat-option *ngFor="let a of getFiltered(artikel, artikel1.value)" [value]="a.id"
								(onSelectionChange)="preis.artikelid = $event.source.value"
							>
								{{ a.toString() }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					
					
					
					<mat-form-field style="width: 200px; margin-right: 0.5em; ">
						<input
							matInput
							[placeholder]="Transportart"
							[matAutocomplete]="auto2"
							aria-label="Number"
							[value]="getTransportartText(preis.transportartid)"
							#transportart1
							(keydown.enter)="tabToNext(transportart1, $event)"
							(keydown.ArrowLeft)="tabToPrevious(transportart1, $event)"
							(keydown.ArrowRight)="tabToNext(transportart1, $event)"
						>
						<mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
							<mat-option *ngFor="let transportart of getFiltered(transportarten, transportart1.value)" [value]="transportart.id"
								(onSelectionChange)="preis.transportartid = $event.source.value"
							>
								{{ transportart.toString() }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					
					
					
					<mat-form-field style="width: 5em; margin-right: 0.5em; ">
						<input
							matInput
							#input1
							type="number"
							placeholder="Grundpreis"
							[value]="preis.grundpreisineur"
							(change)="preis.grundpreisineur = onChange($event, preis); updateFraechterpreis(preis)"
							(keydown.enter)="tabToNext(input1, $event)"
						>
						<span matPrefix>€&nbsp;</span>
					</mat-form-field>
					-
					<mat-form-field style="width: 5em; margin-right: 0.5em; ">
						<input
							matInput
							#input2
							type="number"
							placeholder="Abzug"
							[value]="preis.fraechterpreisabzuginprozent"
							(change)="preis.fraechterpreisabzuginprozent = onChange($event, preis); updateFraechterpreis(preis)"
							(keydown.enter)="tabToNext(input2, $event)"
						>
						<span matSuffix>&nbsp;%</span>
					</mat-form-field>
					=
					<mat-form-field style="width: 5em; margin-right: 0.5em; ">
						<input
							matInput
							#input3
							type="number"
							placeholder="Frächterpreis"
							[value]="preis.fraechterpreisineur"
							(change)="preis.fraechterpreisineur = onChange($event, preis); updateFraechterpreisAbzugProzent(preis)"
							(keydown.enter)="tabToNext(input3, $event)"
						>
						<span matPrefix>€&nbsp;</span>
					</mat-form-field>
					<mat-form-field style="width: 5em; ">
						<input
							matInput
							#input4
							type="number"
							placeholder="Sondermaut"
							[value]="preis.sondermautineur"
							(change)="preis.sondermautineur = onChange($event, preis); "
							(keydown.enter)="tabToNext(input4, $event)"
						>
						<span matPrefix>€&nbsp;</span>
					</mat-form-field>
					
					<button
						type="button"
						mat-mini-fab
						color="warn"
						style="transform: scale(0.83); "
						(click)="deletePreis(preis)"
					><mat-icon>delete_forever</mat-icon></button>
				</div>
				<div>
					<button
						type="button"
						mat-mini-fab
						color="primary"
						(click)="addPreis()"
					><mat-icon>add</mat-icon></button>
				</div>
			</mat-card>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class TarifeComponent extends FormComponent<Tarif>
{
	getTable(): string { return "hensltarife"; }
	
	@ViewChild("vonstation")
	vonstation: AutoCompleteComponent;
	
	stationen: Station[];
	preise: Tarifpreis[];
	artikel: Artikel[];
	transportarten: Transportart[];
	
	getArtikelText(aid) {
		if (this.artikel) {
			for (var a of this.artikel) {
				if (a.id == aid) {
					return a.toString();
				}
			}
		}
		return "";
	}
	
	getTransportartText(tid) {
		if (this.transportarten) {
			for (var t of this.transportarten) {
				if (t.id == tid) {
					return t.toString();
				}
			}
		}
		return "";
	}
	
	getFiltered(artikel, value) {
		if (artikel) {
			value = value.toString().toLowerCase();
			return artikel.filter(a => {
				const optionString = a.toString().toLowerCase();
				return optionString.indexOf(value) !== -1;
			});
		}
		return [];
	}
	
	get vorhandenePreise(): Tarifpreis[] {
		if (this.preise) {
			return this.preise.filter(x => !x._deleted);
		}
		return [];
	}
	
	onChange(e: any, preis: Tarifpreis) {
		e.target.value = +((Math.round(e.target.value * 1000) / 1000).toFixed(3));
		return e.target.value;
	}
	
	onSelectAll(): ExtendedObservable<any[]> {
		// return this.onSelectAll_CustomVariant();
		return this.data.query<any>(null, `
			select
				t.*,
				von.stationsnummer as _vonnummer,
				von.stationsbezeichnung as _vonbezeichnung,
				concat(nach.stationsnummer, ' ', nach.stationsbezeichnung) as _nachstation
			from hensltarife as t
			left join henslstationen as von on von.id = t.vonstationid
			left join henslstationen as nach on nach.id = t.nachstationid
		`).convert(tarife => {
			for (let tarif of tarife) {
				tarif._vonstation = tarif._vonnummer + " " + tarif._vonbezeichnung;
			}
			return tarife;
		});
	}
	
	onSelectAll_CustomVariant(): ExtendedObservable<any[]> {
		return this.data.query<any>(null, `
			select
				t.*,
				von.stationsnummer as _vonnummer,
				von.stationsbezeichnung as _vonbezeichnung,
				concat(nach.stationsnummer, ' ', nach.stationsbezeichnung) as _nachstation
			from hensltarife as t
			left join henslstationen as von on von.id = t.vonstationid
			left join henslstationen as nach on nach.id = t.nachstationid
		`).convert(values => {
			for (let value of values) {
				value._vonstation = value._vonnummer + " " + value._vonbezeichnung;
			}
			return values;
			// return ExtendedObservable.__convertArray(values, value => {
			// 	return this.data.single<any>(null,
			// 		"select * from hensltransportarten where id = " + value.transportartid
			// 	);
			// }, (value, transportart) => {
			// 	value._transportart = transportart.name;
			// 	return value;
			// });
		});
	}
	
	getNew(): Tarif {
		return new Tarif();
	}
	
	onInit() {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		this.data.loader += 3;
		this.loadPreise();
		this.data.selectAll(() => new Station(), "henslstationen").subscribe(stationen => {
			this.stationen = stationen;
			this.data.loader--;
		});
		this.data.selectAll(() => new Artikel(), "henslartikelliste").subscribe(artikelliste => {
			this.artikel = artikelliste;
			this.data.loader--;
		});
		this.data.selectAll(() => new Transportart(), "hensltransportarten").subscribe(transportarten => {
			this.transportarten = transportarten;
			this.data.loader--;
		});
		// if (this.current.grundpreisineur != this.current.fraechterpreisineur && this.current.fraechterpreisabzuginprozent == 0) {
		// 	this.updateFraechterpreisAbzugProzent();
		// }
		
		if (this.params.vonstationid) {
			this.vonstation.setValue(this.params.vonstationid);
			this.vonstation.tabToNext(null);
		} else {
			this.vonstation.focus();
		}
	}
	
	afterSave(callback: () => void): void {
		this.savePreise(() => {
			super.afterSave(callback);
			if (this.params.vonstationid) {
				Utils.goToURL(this.router, this.route, "/hensl/stationen/-1");
			}
		});
	}
	
	savePreise(callback: () => void): void {
		let queries: ExtendedObservable<any>[] = [];
		for (const preis of this.preise) {
			if (preis._deleted) {
				if (preis.id > 0) {
					queries.add(this.data.delete("/api/dynamic/hensltarifpreise", { id: preis.id }));
				}
			} else {
				preis.tarifid = this.current.id;
				queries.add(this.data.set("/api/dynamic/hensltarifpreise", preis));
			}
		}
		if (queries.length == 0) {
			queries.add(ExtendedObservable.empty);
		}
		forkJoin(...queries).subscribe(() => {
			callback();
		});
	}
	
	loadPreise() {
		if (this.current.id == 0) {
			this.preise = [];
			this.addPreis();
			return;
		}
		this.data.loader++;
		this.data.query(() => new Tarifpreis(),
			"select * from hensltarifpreise where tarifid = " + this.current.id
		).subscribe(preise => {
			this.preise = preise;
			this.data.loader--;
		});
	}
	
	addPreis() {
		let preis = new Tarifpreis();
		preis.tarifid = this.current.id;
		this.preise.add(preis);
	}
	
	deletePreis(preis: Tarifpreis) {
		preis._deleted = true;
	}
	
	updateFraechterpreisAbzugProzent(preis: Tarifpreis) {
		let result = 100.0 / preis.grundpreisineur * (preis.grundpreisineur - preis.fraechterpreisineur);
		preis.fraechterpreisabzuginprozent = +(Math.round(result * 10000) / 10000).toFixed(4);
	}
	
	updateFraechterpreis(preis: Tarifpreis) {
		preis.fraechterpreisineur = preis.grundpreisineur / 100 * (100 - preis.fraechterpreisabzuginprozent);
	}
	
	tabToNext(input, e) {
		Utils.tabToNext(input, e);
	}
	
	tabToPrevious(input, e) {
		Utils.tabToPrevious(input, e);
	}
}
