import { Component, Input, ViewContainerRef, OnInit } from "@angular/core";
import { isBoolean } from "util";
import { Utils } from "lib/utils/utils";
import { Sort } from "@angular/material";

@Component({
	selector: 'datatable',
	styles: [`
		table {
			width: 100%;
		}
		
		.mat-row {
			cursor: pointer;
			outline: none;
		}
		
		.mat-row:hover {
			background: #eef;
		}
		
		.mat-header-row {
			background: #eee;
		}
		
		.mat-header-cell {
			font-weight: bold;
			font-size: 0.85em;
			white-space: nowrap;
		}
		
		.mat-cell {
			white-space: nowrap;
			padding: 0 1.3em 0 0;
		}
		
		.table-container {
			position: relative;
			min-height: 450px;
			max-height: 450px;
			overflow: auto;
			background: #f9f9f9;
		}
	`],
	template: `
		
		<mat-card *ngIf="searchbar" style="padding: 1.3em 1.3em 0.5em; ">
			<mat-form-field style="width: 100%; ">
				<input
					matInput
					(input)="applyFilter($event.target.value)"
					placeholder="Suche"
					autocomplete="off">
			</mat-form-field>
		</mat-card>
		
		<div class="mat-elevation-z8 table-wrapper" style="min-height: 4em; ">
			<div class="table-container" *ngIf="dataSource">
				<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort>
					<ng-container *ngFor="let column of columns" matColumnDef="{{ column.name }}">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							{{ getTitle(column) }}
						</th>
						<td mat-cell *matCellDef="let row">
							<a *ngIf="displayValueIsArray(column, row)" (click)="getDisplayValue(column, row)[1]()">{{ getDisplayValue(column, row)[0] }}</a>
							<span *ngIf="!displayValueIsArray(column, row)">{{ getDisplayValue(column, row) }}</span>
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="columnNames"></tr>
					<tr mat-row *matRowDef="let row; columns: columnNames" [routerLink]="clickable ? getRowLink(row) : null" ></tr>
				</table>
			</div>
		</div>
	`
})
export class DataTableComponent
{
	@Input("data-source") dataSource: any;
	@Input("searchbar") searchbar: boolean = true;
	@Input("clickable") clickable: boolean = true;
	@Input("get-row-link") getRowLink: (row: any) => string = row => row.id;
	
	@Input("columns") columns: any[] = [];
	get columnNames(): string[] {
		return this.columns.map(c => c.name);
	}
	
	constructor(private container: ViewContainerRef) {
		
	}
	
	get parent() {
		return Utils.getParent(this.container);
	}
	
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
	
	getTitle(column: any) {
		if (column.label != null) {
			return column.label;
		}
		return column.name;
	}
	
	getDisplayValue(column: any, row: any) {
		let value = row[column.name];
		if (typeof value === 'function') {
			value = value();
		}
		
		switch (column.type) {
			case "euro":
				value = (value as number) * 100;
				return '€ ' + (Math.round(value) / 100).toFixed(2);
			case "date":
				if (value == null) {
					return "01.01.1970";
				}
				if (value instanceof Date) {
					return value.toGermanDateString();
				}
				return (value as string).toGermanDateString();
			case "link":
				return value;
		}
		
		if (isBoolean(value)) {
			return value ? "Ja" : "Nein";
		}
		return value;
	}
	
	displayValueIsArray(column, row) {
		return Array.isArray(this.getDisplayValue(column, row));
	}
	
	sortData(sort: Sort) {
		if (!sort.active || sort.direction === '') {
			
		} else {
			this.dataSource.data = this.dataSource.data.sort(
				(a, b) => {
					let x: any = a[sort.active];
					if (x instanceof String) {
						x = x.toString();
					}
					
					let y: any = b[sort.active];
					if (y instanceof String) {
						y = y.toString();
					}
					
					return (x < y ? -1 : (x > y ? 1 : 0))
						* (sort.direction === 'asc' ? 1 : -1)
				}
			);
		}
	}
	
	test() {
		alert("Test");
	}
}
