import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Transportart } from 'app/hensl/classes/transportart';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { merge } from 'lib/utils/functions';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-transportarten',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Transportarten</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'name', label: 'Name' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neue Transportart</button>
		</div>
		<form *ngIf="id">
			<mat-card>
				<textfield _focus
					label="Name"
					value="current.name"
					[wave]="true"
				></textfield>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Zuschlag pro Einheit</mat-card-subtitle>
				<mat-card-content>
					<eurofield
						label="Zuschlag (€)"
						value="current.zuschlagproeinheitineur"
						[wave]="true"
					></eurofield>
					+
					<numberfield
						label="Zuschlag (%)"
						value="current.zuschlagproeinheitinprozent"
						[wave]="true"
					></numberfield> %
				</mat-card-content>
			</mat-card>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class TransportartenComponent extends FormComponent<Transportart>
{
	getTable(): string { return "hensltransportarten"; }
	
	getNew(): Transportart {
		return new Transportart();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		
	}
}
