import { Observable, interval, forkJoin, of } from "rxjs";
import { cast } from "lib/utils/functions";
import { map, switchMap, startWith } from "rxjs/operators";

export class ExtendedObservable<T> extends Observable<T>
{
	static of<T>(...args): ExtendedObservable<T> {
		return ExtendedObservable.from(of<T>(...args));
	}
	
	static get empty(): ExtendedObservable<any> {
		return ExtendedObservable.of<any>(null);
	}
	
	static from<T>(observable: Observable<T>): ExtendedObservable<T> {
		return cast<ExtendedObservable<T>>(observable, () => new ExtendedObservable<T>());
	}
	
	convert<S>(callback: (next: T) => S): ExtendedObservable<S> {
		return ExtendedObservable.from(this.pipe(map(callback)));
	}
	
	exchange<S>(callback: (next: T) => Observable<S>): ExtendedObservable<S> {
		return ExtendedObservable.from(this.pipe(switchMap(callback)));
	}
	
	interval(period: number): ExtendedObservable<T> {
		return ExtendedObservable.from(
			interval(period).pipe(
				startWith(0)
			)
		).exchange(i => this);
	}
	
	
	
	static __convertArray<R, S, T>(
		array: R[],
		originFunction: (item: R) => ExtendedObservable<S>,
		callback: (item: R, next: S) => T
	): Observable<any> {
		let result: ExtendedObservable<T>[] = [];
		for (let item of array) {
			result.push(
				originFunction(item).convert(
					next => callback(item, next)
				)
			);
		}
		return forkJoin(...result);	
	}
}
