import { IUnique } from "lib/interfaces/i.unique";

export class Fahrzeug implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	fraechterid: number = 0;
	fahrerid: number = 0;
	fahrzeugtypid: number = 0;
	kennzeichen: string = "";
	
	toString() {
		return this.kennzeichen;
	}
}
