import { Router, ActivatedRoute, Params } from "@angular/router";
import { IUnique } from "lib/interfaces/i.unique";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { OnInit, Injector, Injectable } from "@angular/core";
import { Utils } from "lib/utils/utils";
import { FormGroup } from "@angular/forms";
import { DelayedFunction } from "lib/classes/delayed-function";
import { DataService } from "lib/services/data.service";
import { ExtendedObservable } from "lib/classes/extended-observable";
import { DialogBuilder } from "lib/classes/dialog-builder";
import { merge } from "lib/utils/functions";

@Injectable()
export abstract class FormComponent<T extends IUnique> implements OnInit
{
	// jQuery(".mat-drawer-content")[0].scrollTo(0, 800)
	
	abstract getTable(): string;
	abstract getNew(): T;
	abstract onInit(): void;
	abstract onRouteInit(): void;
	abstract onLoadAll(): void;
	abstract onLoadCurrent(): void;
	
	onSelectAll(): ExtendedObservable<T[]> {
		return this.data.query(() => this.getNew(), "select * from " + this.getTable());
	}
	
	onSelectCurrent(): ExtendedObservable<T> {
		return this.data.single(() => this.getNew(), "select * from " + this.getTable() + " where id = " + this.id);
	}
	
	onUpdate(): ExtendedObservable<T> {
		return this.data.set("/api/dynamic/" + this.getTable(), this.current);
	}
	
	onDelete(): ExtendedObservable<Object> {
		return this.data.delete("/api/dynamic/" + this.getTable(), this.current);
	}
	
	onTest(): void {
		
	}
	
	beforeSave(callback: () => void): void {
		this.data.loader++;
		callback();
	}
	
	afterSave(callback: () => void): void {
		if (this.isNew) {
			this.reset();
		} else {
			if (this.goToNewAfterEdit) {
				Utils.goToNew(this.router, this.route);
			} else {
				Utils.goBack(this.router, this.route);
			}
		}
		callback();
		this.data.loader--;
	}
	
	onCancel(): void {
		Utils.goBack(this.router, this.route);
	}
	
	params: Params;
	all: T[];
	current: T;
	id: number;
	loadCurrentFunctions: DelayedFunction[];
	loadCurrentDelayedFunctions: DelayedFunction[];
	
	selectAll$: ExtendedObservable<T[]>
	selectCurrent$: ExtendedObservable<T>
	update$: ExtendedObservable<any>
	delete$: ExtendedObservable<any>
	
	form: FormGroup = new FormGroup({});
	goToNewAfterEdit: boolean = false;
	
	private _dataSource: MatTableDataSource<T> = null;
	// private _functions: any;
	
	// @ViewChild(MatPaginator)
	// private _paginator: MatPaginator;
	
	// @ViewChild(MatSort)
	// private _sort: MatSort;
	
	// @ViewChildren("sort")
	// sort: QueryList<MatSort>;
	// private _sort: MatSort;
	
	public data: DataService;
	protected route: ActivatedRoute;
	protected router: Router;
	protected dialog: MatDialog;
	
	constructor(injector: Injector) {
		this.data = injector.get(DataService);
		this.route = injector.get(ActivatedRoute);
		this.router = injector.get(Router);
		this.dialog = injector.get(MatDialog);
		
		this.route.params.subscribe(params => {
			this.params =  params;
			this.route.queryParams.subscribe(params => {
				this.params = merge(this.params, params);
				this.reset();
			})
		});
	}
	
	get isNew() {
		return this.id < 0
	}
	
	get isCurrent() {
		return this.id > 0;
	}
	
	get isAll() {
		return this.id == 0;
	}
	
	reset() {
		this.data.loader++;
		this.clear();
		this.form.reset();
		this.id = +this.params.id | 0;
		this.current.id = Math.max(0, this.id);
		this.onRouteInit();
		if (this.isAll) {
			this.loadAll();
		} else {
			this.loadCurrent();
		}
	}
	
	ngOnInit(): void {
		// this._paginator._intl.itemsPerPageLabel = 'Einträge pro Seite';
		// this._paginator._intl.firstPageLabel = 'Erste Seite';
		// this._paginator._intl.previousPageLabel = 'Vorherige Seite';
		// this._paginator._intl.nextPageLabel = 'Nächste Seite';
		// this._paginator._intl.lastPageLabel = 'Letzte Seite';
		this.loadCurrentFunctions = [];
		this.loadCurrentDelayedFunctions = [];
		this.onInit();
	}
	
	clear() {
		this.current = this.getNew();
		this.current.id = Math.max(0, this.id);
	}
	
	loadAll() {
		this.selectAll$ = this.onSelectAll();
		this.selectAll$.subscribe(result => {
			this.setAll(result);
			this.onLoadAll();
			this.data.loader--;
		});
	}
	
	setAll(all: T[]) {
		this.all = all;
		this._dataSource = new MatTableDataSource(all);
		// this._dataSource.paginator = this._paginator;
		// this._dataSource.sort = this._sort;
	}
	
	getDataSource() {
		return this._dataSource;
	}
	
	loadCurrent() {
		this.selectCurrent$ = this.onSelectCurrent();
		this.selectCurrent$.subscribe(result => {
			if (result) {
				this.current = result;
			}
			for (let loadCurrentFunction of this.loadCurrentFunctions) {
				loadCurrentFunction.execute();
			}
			this.onLoadCurrent();
			for (let loadCurrentDelayedFunction of this.loadCurrentDelayedFunctions) {
				loadCurrentDelayedFunction.execute();
			}
			this.data.loader--;
		});
	}
	
	save(callback: () => void) {
		this.beforeSave(() => {
			this.update$ = this.onUpdate();
			this.update$.subscribe(result => {
				if (result && result.id) {
					this.current.id = result.id;
				}
				this.afterSave(callback);
			});
		})
	}
	
	cancel() {
		this.onCancel();
	}
	
	_delete(callback: () => void) {
		new DialogBuilder(
			this.dialog,
			"Löschen",
			"Willst du wirklich fortfahren?"
		).ask().dialog.subscribe(yes => {
			if (yes) {
				this.delete$ = this.onDelete();
				this.delete$.subscribe(() => {
					callback();
					Utils.goBack(this.router, this.route);
				});
			} else {
				callback();
			}
		});
	}
	
	test() {
		this.onTest();
	}
}
