import { Component, ViewChild } from "@angular/core";

@Component({
	selector: 'hensl-test',
	styles: [`
		
	`],
	template: `
		
		
		
		<h2>Test</h2>
		
		<input type="hidden" #meineZahl [value]="0">
		
		<div style="font-size: 3em; ">
			{{ meineZahl.value }}
		</div>
		
		<button
			mat-raised-button
			style="background: #db9"
			(click)="halbieren()"
		>Halbieren</button>
		
		<button
			mat-raised-button
			(click)="meineZahl.value =  +meineZahl.value - 1"
		>Vermindern</button>
		
		<button
			mat-raised-button
			color="primary"
			style="background: #393"
			(click)="meineZahl.value = +meineZahl.value + 1"
		>Erhöhen</button>
		
		<button
			mat-raised-button
			color="primary"
			style="background: #963"
			(click)="verdoppeln()"
		>Verdoppeln</button>
		
		<p> Zurück zur <a routerLink="/hensl">Übersicht</a> </p>
		
		<mat-form-field class="wave-input-field no-underline" style="width: 300px; ">
			<input
				matInput
				placeholder="Testwelle"
				autocomplete="off"
			>
			<div class="wave-input-svg-container">
				<svg preserveAspectRatio="none" viewBox="0 0 1200 60" width="300%" height="100%" class="wave-input-svg">
					<path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
				</svg>
			</div>
		</mat-form-field>
	`
})
export class TestComponent
{
	@ViewChild("meineZahl") _meineZahl;
	get meineZahl() {
		return this._meineZahl.nativeElement;
	}
	
	halbieren() {
		this.meineZahl.value = +this.meineZahl.value / 2;
	}
	
	verdoppeln() {
		this.meineZahl.value = +this.meineZahl.value * 2;
	}
}
