import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Rechnungsempfaenger } from 'app/hensl/classes/rechnungsempfaenger';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { merge } from 'lib/utils/functions';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-rechnungsempfaenger',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Rechnungsempfänger</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'name', label: 'Name' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neuer Rechnungsempfänger</button>
		</div>
		<form *ngIf="id">
			<mat-card>
				<div>
					<textfield _focus
						label="Name"
						value="current.name"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<mat-form-field style="width: 100%; ">
						<textarea
							matInput
							placeholder='Adresse'
							[value]="current.adresse"
							(change)="current.adresse = $event.target.value"
							style="height: 5em; "
						></textarea>
					</mat-form-field>
				</div>
				<div>
					<textfield _focus
						label="UID"
						value="current.uid"
						[wave]="true"
					></textfield>
				</div>
			</mat-card>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class RechnungsempfaengerComponent extends FormComponent<Rechnungsempfaenger>
{
	getTable(): string { return "henslrechnungsempfaenger"; }
	
	getNew(): Rechnungsempfaenger {
		return new Rechnungsempfaenger();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		
	}
}
