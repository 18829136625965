import { Component } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Artikel } from 'app/hensl/classes/artikel';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { merge } from 'lib/utils/functions';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-artikel',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Artikel</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'artikelnummer', label: 'Artikelnummer' },
					{ name: 'artikelbezeichnung', label: 'Bezeichnung' },
					{ name: 'einheit', label: 'Einheit' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neuer Artikel</button>
		</div>
		<form *ngIf="id">
			<mat-card>
				<div>
					<textfield _focus
						label="Artikelnummer"
						value="current.artikelnummer"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Artikelbezeichnung"
						value="current.artikelbezeichnung"
						[wave]="true"
					></textfield>
				</div>
				<div>
					<textfield
						label="Einheit"
						value="current.einheit"
						[wave]="true"
					></textfield>
				</div>
			</mat-card>
			<form-buttons [delete]="isCurrent"></form-buttons>
		</form>
	`
}))
export class ArtikelComponent extends FormComponent<Artikel>
{
	getTable(): string { return "henslartikelliste"; }
	
	getNew(): Artikel {
		return new Artikel();
	}
	
	onInit(): void {
		
	}
	
	onRouteInit(): void {
		
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		
	}
}
