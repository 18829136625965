import { IUnique } from "lib/interfaces/i.unique";

export class Fraechter implements IUnique
{
	id: number = 0;
	_deleted: boolean = false;
	
	fraechternummer: string = "";
	fraechtername: string = "";
	adresse: string = "";
	plz: string = "";
	ort: string = "";
	landid: number = 0;
	telefon: string = "";
	email: string = "";
	uid: string = "";
	hatfraechterpreis: boolean = false;
	
	toString() {
		return this.fraechternummer + " " + this.fraechtername
	}
}
