import { Component, Input, OnInit } from "@angular/core";
import { InputComponent } from "lib/components/input.component";
import { Attribut } from "app/hensl/classes/attribut";
import { FormControl, FormArray } from "@angular/forms";
import { Utils } from "lib/utils/utils";
import { forkJoin } from "rxjs";

@Component({
	selector: 'hensl-checktag-list',
	styles: [`
		.mat-checkbox {
			width: 240px;
			max-width: 240px;
			margin: 0 0.5em 0 0;
			display: inline-block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap; 
			vertical-align: middle;
		}
	`],
	template: `
		<mat-card>
			<mat-card-subtitle>Attribute</mat-card-subtitle>
			<mat-card-content>
				<div *ngFor="let a of attribute; index as i">
					
					<div *ngIf="!a.istproduzent">
						<mat-checkbox
							[checked]="checks[i]"
							(click)="onClick(wert, $event, i); wert.focus()"
							(focusin)="wert.focus()"
							[disabled]="wert.disabled"
						>{{ a.toString() }}</mat-checkbox>
						<mat-form-field class="wave-input-field no-underline" style="width: 260px; ">
							<input matInput placeholder="Wert"
								#wert
								[formControl]="controls[i]"
								[value]="values[i] || ''"
								(input)="onInput($event.target.value, i)"
								(focusin)="selectText($event)"
								(keydown.enter)="tabToNextAttribute(wert, $event)"
								(keydown.ArrowLeft)="tabToPreviousAttribute(wert, $event)"
								(keydown.ArrowRight)="tabToNextAttribute(wert, $event)"
								autocomplete="off"
							>
							<div class="wave-input-svg-container">
								<svg preserveAspectRatio="none" viewBox="0 0 1200 60" width="300%" height="100%" class="wave-input-svg">
									<path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
								</svg>
							</div>
						</mat-form-field>
					</div>
					
					<div *ngIf="a.istproduzent">
						<mat-checkbox
							[checked]="checks[i]"
							(click)="onClick(wert, $event, i); wert.focus()"
							[disabled]="wert.disabled"
						>{{ a.toString() }}</mat-checkbox>
						<autocomplete-text
							#wert
							label="Produzent"
							[value]="values[i]"
							(input)="onInput($event.target.value, i)"
							(_onInput2)="onInput($event, i)"
							[from]="produzenten"
						></autocomplete-text>
					</div>
					
				</div>
			</mat-card-content>
		</mat-card>
	`
})
export class CheckTagListComponent extends InputComponent implements OnInit
{
	@Input() subtable: string;
	@Input() idfieldname: string;
	@Input() filter: string;
	
	attribute: Attribut[];
	checks: boolean[];
	values: string[];
	controls: FormControl[] = [];
	
	produzenten: string[] = [];
	
	ngOnInit() {
		
	}
	
	onLoadCurrent() {
		this.parent.data.loader++;
		this.parent.data.query(() => new Attribut(), `
			select * from henslattribute
			where ` + this.filter + " <> 0 order by name"
		).subscribe(result => {
			this.attribute = result;
			for (let i in result) {
				let control = new FormControl();
				this.controls.push(control);
				if (this.form) {
					if (!this.form.controls[this.subtable]) {
						this.form.controls[this.subtable] = new FormArray([]);
					}
					(this.form.controls[this.subtable] as FormArray).push(control);
				}
			}
			this.checks = [];
			this.values = [];
			this.parent.data.query<any>(null, "select id, attributid, wert from "
				+ this.subtable + " where " + this.idfieldname + " = "
				+ this.parent.current.id
			).subscribe(result => {
				for (let attribut of this.attribute) {
					let checked = false;
					let value = "";
					for (let item of result) {
						if (attribut.id == item.attributid) {
							checked = true;
							value = item.wert;
							break;
						}
					}
					this.checks.push(checked);
					this.values.push(value);
				}
				this.produzenten = [];
				this.parent.data.query(null, "select distinct wert from "
					+ "HENSLAuftragsattribute where AttributID = 16 and wert <> ''"
				).subscribe(result => {
					for (let item of result) {
						this.produzenten.push(item.wert);
					}
					this.parent.data.loader--;
				});
			});
		});
	}
	
	changeAndEnable(attribut: Attribut, checked: boolean, value: string) {
		for (let i = 0; i < this.attribute.length; i++) {
			if (this.attribute[i].id == attribut.id) {
				this.checks[i] = checked;
				this.values[i] = value;
				this.controls[i].enable();
				return;
			}
		}
	}
	
	changeAndDisable(attribut: Attribut, checked: boolean, value: string) {
		for (let i = 0; i < this.attribute.length; i++) {
			if (this.attribute[i].id == attribut.id) {
				this.checks[i] = checked;
				this.values[i] = value;
				this.controls[i].disable();
				return;
			}
		}
	}
	
	enableAll() {
		for (let i = 0; i < this.attribute.length; i++) {
			if (this.controls[i].disabled) {
				this.checks[i] = false;
				this.values[i] = "";
				this.controls[i].enable();
			}
		}
	}
	
	onSave() {
		const id0 = this.parent.current.id;
		this.parent.data.query<any>(null, "select id from " + this.subtable
			+ " where " + this.idfieldname + " = " + id0
		).subscribe(result => {
			let queries = [];
			for (let item of result) {
				queries.push(this.parent.data.delete("/api/dynamic/" + this.subtable, item));
			}
			for (let i = 0; i < this.attribute.length; i++) {
				if (this.checks[i]) {
					const id1 = this.attribute[i].id;
					const id2 = this.values[i];
					let data = {
						attributid: id1,
						wert: id2
					};
					data[this.idfieldname] = id0;
					queries.push(this.parent.data.set("/api/dynamic/" + this.subtable, data));
				}
			}
			forkJoin(...queries).subscribe(() => {
				
			});
		});
	}
	
	onInput(value: any, i: number): void {
		this.values[i] = value;
		this.checks[i] = this.values[i] != '';
		if (this.attribute[i].isteindeutig) {
			this.parent.data.query(() => new Attribut(), `
				select ` + this.idfieldname + `, wert from ` + this.subtable
				+ ` where attributid = ` + this.attribute[i].id
				+ ` and ` + this.idfieldname + ` <> `  + this.parent.current.id
				+ ` and wert = '` + value + `'
			`).subscribe(result => {
				if (result.length > 0) {
					this.controls[i].setErrors({ 'notUnique': true });
				}
				if (this.form) {
					this.form.updateValueAndValidity();
				}
			});
		}
	}
	
	onClick(wert, e, i) {
		if (wert.disabled) {
			return;
		}
		if (this.checks[i]) {
			this.values[i] = "";
			this.prevent(e, i);
		} else {
			e.preventDefault();
			this.checks[i] = true;
		}
		wert.focus();
	}
	
	prevent(e, i) {
		e.preventDefault();
		this.checks[i] = this.values[i] != '';
	}
	
	tabToPreviousAttribute(wert, e) {
		Utils.tabTo(wert, e, -1);
	}
	
	tabToNextAttribute(wert, e) {
		Utils.tabTo(wert, e, 1);
	}
}
