import { Utils } from "lib/utils/utils";

String.prototype.toCustomDateString = function(): string {
	let index = this.indexOf('.');
	if (index > -1 && index < 5) {
		let parts = this.split('.');
		if (parts[0].length < 2) parts[0] = '0' + parts[0];
		if (parts[1].length < 2) parts[1] = '0' + parts[1];
		if (parts.length < 3) {
			parts[2] = '' + Utils.getYear();
		} else {
			if (parts[2].length == 0) {
				parts[2] = '' + Utils.getYear();
			} else if (parts[2].length < 4) {
				parts[2] = '' + (2000 + +parts[2]);
			}
		}
		return parts[2] + "-" + parts[1] + "-" + parts[0];
	}
	return this.split('T')[0];
}

String.prototype.toGermanDateString = function(): string {
	let date = this;
	if (date.indexOf('-') !== -1) {
		let parts = date.split('-');
		parts[2] = parts[2].split('T')[0];
		if (parts[1].length < 2) parts[1] = '0' + parts[1];
		if (parts[2].length < 2) parts[2] = '0' + parts[2];
		date = parts[2] + "." + parts[1] + "." + parts[0];
	}
	return date;
}

String.prototype.getDate = function(): Date {
	for (let c of ['-', '.']) {
		if (this.indexOf(c) > -1) {
			const str = this.split(c);
			let year = 0;
			let month = Number(str[1]) - 1;
			let day = Number(str[0]);
			if (str.length < 3) {
				year = Utils.getYear();
			} else {
				if (str[2].length == 0) {
					year = Utils.getYear();
				} else if (str[2].length < 4) {
					year = +str[2] + 2000;
				} else {
					year = +str[2];
				}
			}
			return new Date(year, month, day);
		}
	}
	return null;
}

String.prototype.toHTML = function(): string {
	return this;
}
