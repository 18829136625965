import { Component, ViewChild, Injector, Renderer } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Waagschein } from 'app/hensl/classes/waagschein';
import { Station } from 'app/hensl/classes/station';
import { Fahrzeug } from 'app/hensl/classes/fahrzeug';
import { Artikel } from 'app/hensl/classes/artikel';
import { Rechnungsempfaenger } from 'app/hensl/classes/rechnungsempfaenger';
import { forkJoin } from 'rxjs';
import { Fraechter } from 'app/hensl/classes/fraechter';
import { CheckTagListComponent } from 'app/hensl/components/checktaglist.component';
import { Auftrag } from 'app/hensl/classes/auftrag';
import { Rechnung } from 'app/hensl/classes/rechnung';
import { AutoCompleteComponent } from 'lib/components/autocomplete.component';
import { Utils } from 'lib/utils/utils';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { isNumber } from 'util';
import { SettingsService } from 'app/hensl/services/settings.service';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { merge, call } from 'lib/utils/functions';
import { DialogBuilder } from 'lib/classes/dialog-builder';
import { FocusDirective } from 'lib/directives/focus.directive';
import { Transportart } from 'app/hensl/classes/transportart';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-waagscheine',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Waagscheine</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'waagscheinnummer', label: 'Waagscheinnummer' },
					{ name: 'waagscheindatum', label: 'Datum', type: 'date' },
					{ name: 'strecke', label: 'Strecke' },
					{ name: 'fahrzeug', label: 'Fahrzeug' },
					{ name: 'artikel', label: 'Artikel' },
					{ name: 'preisproeinheitineur', label: 'Preis / Einheit', type: 'euro' },
					{ name: 'menge', label: 'Menge' },
					{ name: 'preisgesamtineur', label: 'Preis (Gesamt)', type: 'euro' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neuer Waagschein</button>
		</div>
		<form [formGroup]="form" *ngIf="id" style="width: 700px; max-width: 100%; float: left; margin-bottom: 2em; margin-right: 2em; ">
			<mat-card>
				<mat-card-subtitle>Strecke</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							#autocompleteVonStation
							label="Von Station"
							value="current.vonstationid"
							[from]="stationen"
							(_change)="updatePreise(); checkAuftraege(false)"
							[wave]="true"
						></autocomplete>
						<autocomplete
							label="Nach Station"
							value="current.nachstationid"
							[from]="stationen"
							(_change)="updatePreise(); checkAuftraege(false)"
							[wave]="true"
						></autocomplete>
					</div>
					<div>
						<autocomplete
							label="Frächter"
							value="current._fraechterid"
							[from]="fraechter"
							(_change)="updateFahrzeuge(true)"
							[wave]="true"
						></autocomplete>
						<autocomplete
							#autocompleteFahrzeuge
							label="Fahrzeug"
							value="current.fahrzeugid"
							[from]="fahrzeuge"
							(_change)="updatePreise()"
							[wave]="true"
						></autocomplete>
					</div>
					<div>
						<eurofield
							label="Maut"
							value="current.mautineur"
						></eurofield>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Kopfbereich</mat-card-subtitle>
				<mat-card-content>
					<div>
						<textfield
							label="Waagscheinnummer"
							value="current.waagscheinnummer"
							[enabled]="false"
						></textfield>
						<datefield
							label="Anlagedatum"
							value="current.anlagedatum"
							[enabled]="false"
						></datefield>
						<datefield
							#waagscheindatum
							label="Waagscheindatum"
							value="current.waagscheindatum"
						></datefield>
						<div *ngIf="!data.loading && !auftragsnummer" style="position: absolute; top: -0.7em; right: 0.5em; ">
							<div style="display: inline-block; margin-right: 0.5em; ">
								Auftrag suchen
							</div>
							<button
								type="button"
								mat-mini-fab
								color="primary"
								style="transform: scale(0.83); "
								[disabled]="dialogBuilder"
								(click)="$event.preventDefault(); checkAuftraege(true)"
							><mat-icon>search</mat-icon></button>
						</div>
					</div>
					<div>
						<timefield
							label="Ladezeit"
							value="current.ladezeit"
						></timefield>
						-
						<timefield
							label="Ende"
							value="current.ladezeitende"
						></timefield>
						<span style="display: inline-block; width: 2em; "></span>
						<timefield
							label="Entladezeit"
							value="current.entladezeit"
						></timefield>
						-
						<timefield
							label="Ende"
							value="current.entladezeitende"
						></timefield>
					</div>
					<div *ngIf="auftragsnummer">
						<textfield
							label="Auftragsnummer"
							value="auftragsnummer"
							[enabled]="false"
						></textfield>
						<button
							type="button"
							mat-mini-fab
							color="primary"
							style="transform: scale(0.83); "
							[routerLink]="'/hensl/auftraege/' + current.auftragid"
							[disabled]="dialogBuilder"
							(click)="$event.preventDefault()"
						><mat-icon>link</mat-icon></button>
						<button
							type="button"
							mat-mini-fab
							color="warn"
							style="transform: scale(0.83); "
							[disabled]="dialogBuilder"
							(click)="vonAuftragEntfernen(); checkAuftraege(false)"
						><mat-icon>delete</mat-icon></button>
					</div>
					<div>
						<span *ngIf="rechnungsnummer">
							<button
								mat-raised-button
								type="button"
								[routerLink]="'/hensl/rechnungen/' + current.rechnungid"
								(click)="$event.preventDefault()"
								style="margin-right: 1em; "
							>Rechnung anzeigen</button>
						</span>
						<span *ngIf="false">
							<button
								mat-raised-button 
								type="button"
								(click)="$event.preventDefault(); rechnungErstellen()"
								style="margin-right: 1em; "
							>Rechnung erstellen</button>
						</span>
						<checkbox
							label="Verrechnet"
							value="current.verrechnet"
						></checkbox>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Transport</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							label="Artikel"
							value="current.artikelid"
							[from]="artikel"
							(_change)="updatePreise(); updateArtikelEinheit()"
							[wave]="true"
						></autocomplete>
						<autocomplete
							label="Transportart"
							value="current.transportartid"
							[from]="transportarten"
							(_change)="updatePreise()"
							[wave]="true"
						></autocomplete>
					</div>
					<div *ngIf="current._preisproeinheitoriginal">
						<span style="display: inline-block; width: 300px; ">Einzelpreis von Tarif:</span>{{ current._preisproeinheitoriginal | currency:'EUR' }}<br>
						<span style="display: inline-block; width: 300px; ">Zuschlag pro Einheit von Transportart:</span>{{ current._zuschlag | currency:'EUR' }}<br>
						<span style="display: inline-block; width: 300px; ">Zuschlag (in Prozent) von Transportart:</span>{{ current._zuschlagprozent }} %<br>
						<span style="display: inline-block; width: 300px; ">Neuer Einzelpreis:</span>{{ current.preisproeinheitineur | currency:'EUR' }}<br>
						<br>
					</div>
					<div *ngIf="current._preisproeinheitoriginal == 0">
						
					</div>
					<div>
						<numberfield
							label="Menge"
							value="current.menge"
							(_change)="updateGesamtPreis()"
							[wave]="true"
						></numberfield> {{ artikeleinheit }}
						<span style="display: inline-block; margin: 0 0.5em 0 2em; color: #999; ">
							x
						</span><numberfield
							label="Fuhrenanzahl"
							value="current.fuhrenanzahl"
							(_change)="updateGesamtPreis()"
							[wave]="true"
						></numberfield>
					</div>
					<div>
						<eurofield
							label="Preis / Einheit"
							value="current.preisproeinheitineur"
							(_change)="updateGesamtPreis()"
						></eurofield>
						<span style="display: inline-block; margin: 0 0.5em; color: #999; ">
							+
						</span>
						<numberfield
							label="Energiek.zuschl."
							value="current.energiekostenzuschlaginprozent"
							(_change)="updateGesamtPreis()"
							[wave]="true"
						></numberfield> %
						<span style="display: inline-block; margin: 0 0.5em; color: #999; ">
							+
						</span>
						<eurofield
							label="Sondermaut"
							value="current.sondermautineur"
							(_change)="updateGesamtPreis()"
						></eurofield>
						<span style="display: inline-block; margin: 0 0.5em; color: #999; ">
							=
						</span>
						<eurofield
							label="Preis (Gesamt)"
							value="current.preisgesamtineur"
						></eurofield>
						<button
							type="button"
							mat-mini-fab
							color="primary"
							style="transform: scale(0.83); "
							(click)="updatePreise()"
						><mat-icon>refresh</mat-icon></button>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Rechnungsempfänger</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							#autocompleteRechnungsempfaenger
							label="Rechnungsempfänger"
							value="current.rechnungsempfaengerid"
							[from]="rechnungsempfaenger"
							[wave]="true"
							(_change)="updateRechnungsadresse()"
							(_onKeyDownEnter)="autocompleteRechnungsempfaenger.tabTo($event, 2)"
							(_onKeyDownArrowRight)="autocompleteRechnungsempfaenger.tabTo($event, 2)"
						></autocomplete>
					</div>
					<div>
						<mat-form-field style="width: 100%; ">
							<textarea
								matInput
								placeholder='Rechnungsadresse'
								[value]="current.rechnungsadresse"
								(change)="current.rechnungsadresse = $event.target.value"
								style="height: 5em; "
							></textarea>
						</mat-form-field>
					</div>
				</mat-card-content>
			</mat-card>
			<hensl-checktag-list
				#checkTagList
				subtable="henslwaagscheinattribute"
				idfieldname="waagscheinid"
				filter="fuerwaagscheine"
			></hensl-checktag-list>
			<form-buttons [delete]="false"></form-buttons>
		</form>
		<mat-card *ngIf="id" style="width: 550px; max-width: 100%; float: left; ">
			<mat-card-title>Letzten {{ getMaxWaagscheine() }} Waagscheine</mat-card-title>
			<mat-card-content>
				<div *ngFor="let waagschein of heutigeWaagscheine" style="margin-bottom: 0.4em; ">
					<div style="float: left; width: 100px; ">
						<a routerLink="../{{ waagschein.id }}">
							{{ waagschein.waagscheinnummer }}
						</a>
					</div>
					<div style="float: left; width: 240px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; ">
						{{ waagschein.strecke }}
					</div>
					<div style="float: left; width: 110px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; ">
						{{ waagschein.artikel }}
					</div>
					<div style="float: right; width: 100px; text-align: right; ">
						{{ waagschein.preisgesamtineur | number:'1.2-2' }} €
					</div>
					<div style="clear: both; "></div>
				</div>
				<div>
					<a routerLink="../-1" style="margin-top: 1em; ">+ Neuer Waagschein</a>
				</div>
			</mat-card-content>
		</mat-card>
	`
}))
export class WaagscheineComponent extends FormComponent<Waagschein>
{
	@ViewChild("waagscheindatum")
	waagscheindatum: any;
	
	@ViewChild("autocompleteVonStation")
	autocompleteVonStation: AutoCompleteComponent;
	
	@ViewChild("autocompleteRechnungsempfaenger")
	autocompleteRechnungsempfaenger: AutoCompleteComponent;
	
	@ViewChild("autocompleteFahrzeuge")
	autocompleteFahrzeuge: AutoCompleteComponent;
	
	getTable(): string { return "henslwaagscheine"; }
	
	stationen: Station[];
	fraechter: Fraechter[];
	fahrzeuge: Fahrzeug[];
	transportarten: Transportart[];
	artikel: Artikel[];
	rechnungsempfaenger: Rechnungsempfaenger[];
	
	heutigeWaagscheine: Waagschein[];
	auftragsnummer: string;
	rechnungsnummer: string;
	
	dialogBuilder: DialogBuilder;
	
	artikeleinheit: string;
	
	@ViewChild("checkTagList")
	checkTagList: CheckTagListComponent;
	
	constructor(
		private settings: SettingsService,
		private renderer: Renderer,
		injector: Injector
	) {
		super(injector);
		settings.load(() => {
			this.updateHeutigeWaagscheine();
		});
	}
	
	onSelectAll(): ExtendedObservable<Waagschein[]> {
		return Waagschein.selectAll(this.data, 200);
	}
	
	onSelectCurrent(): ExtendedObservable<Waagschein> {
		return Waagschein.select(this.data, this.id);
	}
	
	getNew(): Waagschein {
		return new Waagschein(this.data);
	}
	
	onInit(): void {
		this.goToNewAfterEdit = true;
	}
	
	onRouteInit(): void {
		this.auftragsnummer = null;
		this.rechnungsnummer = null;
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		this.data.loader++;
		forkJoin(
			this.data.query(() => new Station(), `
				select s1.*, s2.stationsnummer as _produzentheimstationsnummer
				from henslstationen as s1
				left join henslstationen as s2 on s2.id = s1.produzentheimstationid
			`),
			this.data.selectAll(() => new Fraechter(), "henslfraechterliste"),
			this.data.selectAll(() => new Artikel(), "henslartikelliste"),
			this.data.selectAll(() => new Transportart(), "hensltransportarten"),
			this.data.selectAll(() => new Rechnungsempfaenger(), "henslrechnungsempfaenger")
		).subscribe(results => {
			this.stationen = results[0];
			this.fraechter = results[1];
			this.artikel = results[2];
			this.transportarten = results[3];
			this.rechnungsempfaenger = results[4];
			if (this.isNew) {
				Waagschein.neueWaagscheinnummer(this.data).convert(x => x.nummer).subscribe(nummer => {
					this.current.waagscheinnummer = Utils.getYear() + "-" + nummer;
				});
			} else {
				this.autocompleteVonStation.updateFilteredOptions([], "");
				if (this.current.auftragid) {
					this.current._auftrag.subscribe(result => {
						this.auftragsnummer = result.auftragsnummer;
					})
				}
				if (this.current.rechnungid) {
					this.current._rechnung.subscribe(result => {
						this.rechnungsnummer = result.rechnungsnummer;
					});
				}
			}
			this.checkTagList.onLoadCurrent();
			this.checkAuftraege(false, () => {
				this.updateArtikelEinheit();
				this.updateFahrzeuge(false);
				//FocusDirective.focus(this.renderer, this.waagscheindatum);
				FocusDirective.focus(this.renderer, this.autocompleteVonStation);
			});
			this.data.loader--;
		});
	}
	
	updateArtikelEinheit() {
		this.current._artikel.subscribe(artikel => {
			this.artikeleinheit = artikel ? artikel.einheit : "";
		});
	}
	
	beforeSave(callback: () => void): void {
		if (this.isNew) {
			Waagschein.neueWaagscheinnummer(this.data).subscribe(result => {
				this.current.saveNummernkreis(result).subscribe(() => {
					super.beforeSave(callback);
				});
			});
		} else {
			super.beforeSave(callback);
		}
	}
	
	afterSave(callback: () => void): void {
		this.checkTagList.onSave();
		this.updateHeutigeWaagscheine();
		super.afterSave(callback);
	}
	
	updateHeutigeWaagscheine() {
		return Waagschein.selectAll(this.data, this.getMaxWaagscheine()).subscribe(result => {
			this.heutigeWaagscheine = result;
		});
	}
	
	updateFahrzeuge(updateFilter) {
		this.current._fahrzeuge.subscribe(fahrzeuge => {
			this.fahrzeuge = fahrzeuge;
			if (updateFilter) {
				this.autocompleteFahrzeuge.updateFilteredOptions(fahrzeuge, "");
			}
		});
	}
	
	updateRechnungsadresse() {
		const id = this.current.rechnungsempfaengerid;
		if (!id) {
			return;
		}
		for (const x of this.rechnungsempfaenger) {
			if (x.id === id) {
				console.log(this.current.rechnungsadresse, x);
				this.current.rechnungsadresse = x.adresse;
				return;
			}
		}
	}
	
	updatePreise() {
		if (
			isNumber(this.current.vonstationid) &&
			isNumber(this.current.nachstationid) &&
			isNumber(this.current.artikelid)
		) {
			this.current._updatePreise.subscribe(() => {
				this.updateGesamtPreis();
			});
		}
	}
	
	updateGesamtPreis() {
		let gesamtpreis = 0;
		
		gesamtpreis += this.current.preisproeinheitineur * this.current.menge;
		gesamtpreis += gesamtpreis * (this.current.energiekostenzuschlaginprozent * 0.01);
		gesamtpreis *= this.current.fuhrenanzahl;
		gesamtpreis += this.current.sondermautineur;
		
		this.current.preisgesamtineur = gesamtpreis;
		
	}
	
	checkAuftraege(forceDialog: boolean, callback?: () => void) {
		if (this.current.auftragid > 0) {
			call(callback);
			return;
		}
		if (+this.current.vonstationid > 0 &&
			+this.current.nachstationid > 0)
		{
			this.dialogBuilder = new DialogBuilder(this.dialog);
			this.current._moeglicheAuftraege.subscribe(auftraege => {
				if (auftraege.length > 0) {
					this.dialogBuilder.title = "Mögliche Aufträge";
					this.dialogBuilder.text = "";
					this.dialogBuilder.options = auftraege;
					let result = this.dialogBuilder.ask();
					result.dialog.subscribe(yes => {
						if (yes) {
							this.zuAuftragHinzufuegen(result.instance.selectedOption);
						}
						this.dialogBuilder = null;
						call(callback);
					})
				} else if (forceDialog) {
					this.dialogBuilder.title = "Information";
					this.dialogBuilder.text = "Es wurde kein passender Auftrag gefunden.";
					this.dialogBuilder.options = [];
					this.dialogBuilder.info().dialog.subscribe(() => {
						this.dialogBuilder = null;
						call(callback);
					});
				} else {
					this.dialogBuilder = null;
					call(callback);
				}
			});
		} else {
			call(callback);
		}
	}
	
	zuAuftragHinzufuegen(auftrag: Auftrag) {
		this.current.auftragid = auftrag.id;
		this.auftragsnummer = auftrag.auftragsnummer;
		
		this.current._fraechterid = auftrag._fraechterid;
		this.updateFahrzeuge(false);
		
		this.current.rechnungsempfaengerid = auftrag.rechnungsempfaengerid;
		this.current.rechnungsadresse = auftrag.rechnungsadresse;
		this.current.fahrzeugid = auftrag.fahrzeugid;
		this.current.artikelid = auftrag.artikelid;
		this.current.transportartid = auftrag.transportartid;
		this.current.preisproeinheitineur = auftrag.preisproeinheitineur;
		this.current.menge = auftrag.menge;
		this.current.preisgesamtineur = auftrag.preisgesamtineur;
		this.current.sondermautineur = auftrag.sondermautineur;
		
		auftrag._attribute.subscribe(attribute => {
			this.checkTagList.enableAll();
			for (let attribut of attribute) {
				if (attribut.isteindeutig) {
					this.checkTagList.changeAndDisable(attribut, true, attribut.wert);
				} else {
					this.checkTagList.changeAndEnable(attribut, true, attribut.wert);
				}
			}
		});
	}
	
	vonAuftragEntfernen() {
		this.current.auftragid = 0;
		this.auftragsnummer = null;
		this.checkTagList.enableAll();
	}
	
	rechnungErstellen() {
		console.log(this.current);
	}
	
	getMaxWaagscheine() {
		return this.settings.getValue('view.maxHeutigeAuftraege');
	}
	
	getDefaultTransportart() {
		return this.settings.getValue('form.defaultTransportart');
	}
}
