declare var $: any;

import { ViewContainerRef, Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { isDefined, cast } from "lib/utils/functions";

export class Sequence
{
	running: boolean;
	functions: ((next: () => void) => void)[];
	
	constructor(firstFunction?: (next: () => void) => void) {
		this.running = false;
		this.functions = [];
		this.then(firstFunction);
	}
	
	static run(functions: (() => void)[]) {
		let sequence = new Sequence();
		for (let f of functions) {
			sequence.then(next => {
				f();
				next();
			})
		}
		sequence.start();
	}
	
	static begin(firstFunction?: (next: () => void) => void) {
		return new Sequence(firstFunction);
	}
	
	then(nextFunction: (next: () => void) => void): Sequence {
		if (nextFunction) {
			this.functions.push(nextFunction);
		}
		return this;
	}
	
	start(finalFunction?: () => void): Sequence {
		this.running = true;
		const _this = this;
		const _functions = _this.functions;
		const next = function(functions) {
			if (functions.length > 0) {
				functions[0](() => {
					functions.splice(0, 1);
					next(functions);
				});
			} else {
				if (finalFunction) {
					finalFunction();
				}
				_this.running = false;
			}
		}
		next(_functions);
		return _this;
	}
}

export class Utils
{
	static getYear(): number {
		return (new Date()).getFullYear();
	}
	
	static getHours(timeString: string): number {
		return +timeString.split(':')[0];
	}
	
	static getMinutes(timeString: string): number {
		return +timeString.split(':')[1];
	}
	
	static getSelf(container: ViewContainerRef): any {
		return container.injector["view"].component;
	}
	
	static getParent(container: ViewContainerRef): any {
		return container.injector["view"].parent.component;
	}
	
	static goTo(router: Router, route: ActivatedRoute, ...args: any[]) {
		router.navigate(args, { relativeTo: route });
	}
	
	static goToURL(router: Router, route: ActivatedRoute, url: string) {
		router.navigateByUrl(url, { relativeTo: route });
	}
	
	static goToNew(router: Router, route: ActivatedRoute) {
		Utils.goTo(router, route, '../-1');
	}
	
	static goBack(router: Router, route: ActivatedRoute) {
		Utils.goTo(router, route, '../');
	}
	
	static get<T>(key: string, newT: () => T): T {
		let result = JSON.parse(localStorage.getItem(key));
		return cast<T>(result, newT);
	}
	
	static set<T>(key: string, value: T): void {
		localStorage.setItem(key, JSON.stringify(value));
	}
	
	static tabTo(input, e, offset) {
		let fields = $(input).parents('form:eq(0), body').find(
			'input:not(:checkbox):not([disabled]), textarea, select'
		);
		let index = fields.index(input) + offset;
		if (0 <= index && index < fields.length) {
			if (e && e.preventDefault) {
				e.preventDefault();
			}
			fields.eq(index).focus();
		}
	}
	
	static tabToPrevious(input, e) {
		this.tabTo(input, e, -1);
	}
	
	static tabToNext(input, e) {
		this.tabTo(input, e, 1);
	}
	
	static createURL(url: string, params: any) {
		return url + '?' + Object.keys(params).map(key => {
			return encodeURIComponent(key) + '='
				+ encodeURIComponent(params[key]);
		}).join('&');
	}
	
	static getInternalHostname() : string {
		//return window.location.hostname;
		return 'hensl-internal.chvaco.at';
	}
}
